export function Init(type) {
    const CN = {
        Login: {
            Main: {
                Email: '信箱',
                Password: '密碼',
                Remember: '記得我',
                Forget: '忘記密碼',
            },
            Button: {
                SingIn: '登陸',
                Or: '或',
                SingUp: '註冊'
            }
        },
        SignOut:'登出'
    };
    const EN = {
        Login: {
            Main: {
                Email: 'Email address',
                Password: 'Password',
                Remember: 'Remember me',
                Forget: 'Forgot your password',
            },
            Button: {
                SingIn: 'Sign in',
                Or: 'Or',
                SingUp: 'Sign up'
            }
        },
        SignOut:'Sign Out'
    };
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

