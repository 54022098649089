import { CheckIcon } from '@heroicons/react/20/solid'
export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">

        <p className='text-left mt-2'>
          Dojo Technologies Limited (the "Company") takes your privacy seriously. The main purpose of this Privacy Policy is to inform you of how we treat the Personal Information that the Company collects and receives when you use the Company's website located at https://www.algodojo.com (the "Website") and its software platform (the "Software"). Company collects and uses your Personal Information to operate and improve its Website and Software. In addition, our uses include ease in maneuvering our Website by eliminating the need to repeatedly enter the same information; protecting the rights and property of Company or our Users; ensuring functionality of our Website and ; to develop new services; and to communicate with you.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          1. User Consent
        </h3>
        <p className='text-left mt-2'>
          By submitting Personal Information through our Website or Software, you agree to the terms of this Privacy Policy and you expressly consent to the collection, use, and disclosure of the Personal Information in accordance with this Privacy Policy.
        </p>

        <h3 className='text-left mt-8 text-lg font-bold'>
          2. A Note to -Users Outside of the United States
        </h3>
        <p className='text-left mt-2'>
          If you are a non-U.S. user of the Website or Software, by visiting the Website, accessing the Software, and/or providing us with information, you acknowledge and agree that your Personal Information may be processed for the purposes identified in this Privacy Policy. In addition, your Personal Information may be processed in the country in which it was collected and in other countries, including the United States, where laws regarding processing of Personal Information may be less stringent than the laws in your country. By providing your information, you consent to such transfer and use.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          3. Types of Information We Collect
        </h3>
        <p className='text-left mt-2'>
          Personal information is information about you such as your name, email address or phone number, and that is not otherwise publicly available. When you register for the Website, Company collects personal information including, at a minimum, your email address. Company uses Twilio Inc. to process account signups and logins.
        </p>
        <p className='text-left mt-2'>
          When you purchase a paid product or service, you may provide a credit card number or other payment card information. Company uses Neweb Technologies Co., Ltd. to process orders for such services that are made through the Website. Therefore, such information will also be governed by Neweb Technologies Co., Ltd.'s privacy policy. Because payment details are sent directly to Neweb Technologies Co., Ltd., Company never has access to your credit card number or security code.
        </p>
        <p className='text-left mt-2'>
          When you run the Software on your computer, the Software connects to Company's central servers to validate your license key and, if applicable, your product permissions. Software logs your license key and IP to Company's central servers. When you run the Software on your computer, the Software sends basic usage data in the form of HTTP access logs. Company uses this information to understand which services are used and to improve our products.
        </p>
        <p className='text-left mt-2'>
          Company does not have access to any additional information generated through your use of the Software such as your trade activity, brokerage account numbers or balances, third-party API credentials, strategy source code, or data. The Software records various details in internal log files, but we do not have access to the log files unless you provide them to us for technical support.
        </p>


        <h3 className='text-left mt-8 text-lg font-bold'>
          4. How We Use Your Information
        </h3>
        <p className='text-left mt-2'>
          In general, Personal Information you submit to us regarding you or your company is used either to respond to requests that you make, or to aid us in serving you better. We use such Personal Information in the following ways:

          · to facilitate the creation of and secure your Account on our network;

          · to identify you as a user in our system;

          · to provide improved administration of our Website and Software;

          · to provide the services you request;

          · to improve the quality of experience when you interact with our Website, and Software;

          · to send you administrative e-mail notifications, such as security or support and maintenance advisories;

          · to send newsletters, surveys, offers, and other promotional materials related to our Software and for other marketing purposes;

          · to market products, services and events to you that we believe may be of interest, both on and off the Website.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          5. Disclosure of Your Personal Informatio
        </h3>
        <p className='text-left mt-2'>
          We disclose your Personal Information as described below and as described elsewhere in this Privacy Policy.
        </p>
        <h3 className='text-left mt-2 text-lg'>
          1. Third-party Vendors
        </h3>
        <p className='text-left mt-2'>
          When your purchase third-party data or products through the Website, we share information related to the purchase with the third-party vendor. This information may include your email address, the product and date of purchase, and any other relevant information related to the purchase. These third-party vendors may be located in countries other than the United States.
        </p>
        <h3 className='text-left mt-2 text-lg'>
          2. Third-Party APIs
        </h3>
        <p className='text-left mt-2'>
          When you connect the Software to third-party APIs, the Software sends your API credentials to the third-party servers, and such use of third-party APIs will be governed by the privacy policies of the third party. Your third-party API credentials are encrypted at rest and never leave the Software other than for the purpose of communicating with the third-party API. The Company does not have access to your third-party API credentials or any communications between the Software and the third-party API.
        </p>
        <h3 className='text-left mt-2 text-lg'>
          3. Other Disclosures
        </h3>
        <p className='text-left mt-2'>
          In addition, we may disclose personal information in the following limited circumstances: (i) to protect, enforce, or defend the legal rights, privacy, safety, or property of Company, our Affiliates or their employees, agents and contractors (including enforcement of our agreements and our Terms of Use); (ii) to protect the safety, privacy, and security of Users of our products and services or members of the public; (iii) to protect against fraud or for risk management purposes; (iv) to comply with the law or legal process, including laws outside your country of residence; or (v) to respond to requests from public and government authorities including public and government authorities outside your country of residence.
        </p>
        <h3 className='text-left mt-2 text-lg'>
          4. Anonymous Information
        </h3>
        <p className='text-left mt-2'>
          We may share aggregate information (e.g. number of website visits, demographic breakdown, etc.) to third parties by combining aspects of personal information into an anonymous pool.
        </p>

        <h3 className='text-left mt-8 text-lg font-bold'>
          6. Location of Data
        </h3>
        <p className='text-left mt-2'>
          Your Personal Information will be stored and processed by the Company with Amazon Web Services (AWS). When you purchase products from third-party vendors through the Website, we share data about your purchase with the third-party vendor (as explained in Section 5.2 above) and such data will be processed by the third-party vendor in the country where they operate.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          7. Third-Party Websites
        </h3>
        <p className='text-left mt-2'>
          When you click on a link to any Third Party Service, you will leave our Website or Software and go to a Third Party Service, which may collect Personal Information or anonymous information from you. Please be aware that the terms of this Privacy Policy do not apply to these Third Party Services, or to manner by which they collect or use your Personal Information.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          8. Marketing
        </h3>
        <p className='text-left mt-2'>
          We will periodically send you free newsletters and e-mails that directly promote the use of our Software. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to "opt-out" by following the unsubscribe instructions provided in the e-mail you receive.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          9. Retention of Personal Information
        </h3>
        <p className='text-left mt-2'>
          When you send email or other communications to Company, we retain those communications for a certain period of time in order to process your questions and concerns, respond to your requests, and improve our services.
        </p>
        <p className='text-left mt-2'>
          Personal information that we collect, access or process will be retained only as long as necessary for the fulfilment of the purposes for which it was collected, unless required or authorized by law or another legitimate interest of the Company. Personal Information that is no longer required to fulfill the identified purposes will be destroyed, erased or made de-identified.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          10. Confidentiality and Securit
        </h3>
        <p className='text-left mt-2'>
          Company uses physical, electronic and procedural safeguards and technologies to protect personal information from unauthorized access, use or disclosure. Company limits access to your Personal Information to our employees, contractors, and agents who we reasonably believe need to gain access to such information in order to do their jobs and to operate, implement, develop, perform or improve our services. These individuals are bound by confidentiality obligations to Company and may be subject to termination or legal actions if they fail to comply with these obligations.

          Company is committed to protecting the security of your Personal Information. We use a variety of industry-standard security technologies and procedures to help protect your Personal Information from unauthorized access, use, or disclosure. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while Company uses reasonable efforts to protect your Personal Information, Company cannot guarantee its absolute security. We also require you to enter a password to access your Account information. Please do not disclose your Account password to unauthorized people.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          11. Cookies
        </h3>
        <p className='text-left mt-2'>
          When you visit our Website, Company sends one or more "cookies" to your computer or other device. A cookie is a small text file that is placed on your hard disk by a web server. Cookies help us keep a record of your preferences and other information on your computer to save time from repeatedly entering the same information on your later visits to our website. We use cookies to customize the content, to improve our services; to conduct research, audit, and analysis in order to improve and maintain our services; to develop new services; to protect the rights of the Company and its business partners and our users; to track users trends; and to contact you.
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          12. GDPR Data Protection Rights for EU Customers
        </h3>
        <p className='text-left mt-2'>
          If you are located in the European Union, the Company would like to make sure you are fully aware of all of your data protection rights. Every EU user is entitled to the following:

          · The right to access – You have the right to request the Company for copies of your personal data. We may charge you a small fee for this service.

          · The right to rectification – You have the right to request that the Company correct any information you believe is inaccurate. You also have the right to request the Company to complete the information you believe is incomplete.

          · The right to erasure – You have the right to request that the Company erase your personal data, under certain conditions. Please note that we may be required to keep this information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements). When we delete any information, it will be deleted from the active database, but may remain in our archives. We may retain your information for fraud investigation or similar purposes.

          · The right to restrict processing – You have the right to request that the Company restrict the processing of your personal data, under certain conditions.

          · The right to object to processing – You have the right to object to the Company’s processing of your personal data, under certain conditions.

          · The right to data portability – You have the right to request that the Company transfer the data that we have collected to another organization, or directly to you, under certain conditions. If you make a request, please contact us at support@algodojo.com.

          Should you wish to report a complaint or if you feel that the Company has not addressed your concern in a satisfactory manner, you can get more information by contacting the EU Data Protection Supervisor, please see https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en
        </p>
        <h3 className='text-left mt-8 text-lg font-bold'>
          13. Changes to This Policy
        </h3>
        <p className='text-left mt-2'>
          Company may modify this Privacy Policy. Please look at the Effective Date at the top of this Privacy Policy to see when this Privacy Policy was last revised. Any changes to this Privacy Policy will become effective when we post the revised Privacy Policy on the Website. Your use of the Website, , Software, or any other of our services or your provision of Personal Information to us following these changes means that you accept the revised Privacy Policy.
        </p>
      </div>
    </div>
  )
}
