import News from './News_2';
export default function Example() {
  return (
    <div className="mx-auto max-w-2xl h-screen px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md my-4">
            {/* <img
              className="mx-auto h-20 w-auto"
              src="images/logo.png"
              alt="Your Company"
            /> */}
          </div>
      <div className="bg-white rounded-lg h-5/6 mt-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <News></News>
        </div>
      </div>
    </div>
  )
}
