/* This example requires Tailwind CSS v2.0+ */
import Templates_detail from './templates_detail';
import { Init } from '../language/templates/templates_lang';
const LANGUAGE = Init(localStorage.lang);
export default function Example() {
  return (
    <div className="">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="my-6 text-center text-3xl font-bold tracking-tight text-white">{LANGUAGE.Title}</h2>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

          <Templates_detail>
          </Templates_detail>
        </div>
      </div>
    </div>
  )
}
