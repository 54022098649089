import React from 'react';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Enum } from '../enum/enum';
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);

let referrals_code = "";
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false,
    };
    this.Reload = this.Reload.bind(this);
    this.GetData = this.GetData.bind(this);

    this.Reload();
  };
  async GetData() {
    await axios.post(Enum().url.db_referrals, '')
      .then((res) => {
        let _ReferralsData = JSON.parse(res.data).Data;
        console.log("ReferralsData", _ReferralsData);
        referrals_code = Enum().url.referralsUrl + _ReferralsData.referrals_code
        console.log("referrals_code", referrals_code);
        document.getElementById('referrals_code_p').innerHTML = referrals_code;
        this.state.value = referrals_code
        console.log("this.state.referrals_code", this.state.value);

      })
      .catch(err => {
        console.error(err);
      });

    await axios.get(Enum().url.getReferralsData, '')
      .then((res) => {
        let _ReferralsData = res.data.Data;
        console.log("ReferralsData", _ReferralsData);
        _ReferralsData.forEach(element => {

          let personIdx = 0;

          let Name = element.name;
          let Date = element.date;
          let Commission = element.commission;
          var _TrElement = document.createElement("tr");
          var class_tr = document.createAttribute('class');
          class_tr.value = (personIdx % 2 === 0 ? undefined : 'bg-gray-50');
          _TrElement.setAttributeNode(class_tr);

          var _TdElement1 = document.createElement("td");
          var class1 = document.createAttribute('class');
          class1.value = "whitespace-nowrap px-3 py-4 text-sm text-bk";
          _TdElement1.setAttributeNode(class1);

          var _TdElement2 = document.createElement("td");
          var class2 = document.createAttribute('class');
          class2.value = "whitespace-nowrap px-3 py-4 text-sm text-bk";
          _TdElement2.setAttributeNode(class2);

          var _TdElement3 = document.createElement("td");
          var class3 = document.createAttribute('class');
          class2.value = "whitespace-nowrap px-3 py-4 text-sm text-bk";
          _TdElement3.setAttributeNode(class3);

          var text = document.createTextNode(Name);
          _TdElement1.appendChild(text);
          _TrElement.appendChild(_TdElement1);

          text = document.createTextNode(Date);
          _TdElement2.appendChild(text);
          _TrElement.appendChild(_TdElement2);

          text = document.createTextNode(Commission);
          _TdElement3.appendChild(text);
          _TrElement.appendChild(_TdElement3);

          document.getElementById('dataset').appendChild(_TrElement);
          personIdx = personIdx + 1;
        });
      })
      .catch(err => {
        console.error(err);
      });
  };
  Reload() {
    console.log('reload');
    this.GetData();
  };
  GetReferralsCode() {
    return referrals_code;
  }
  render() {
    return (
      <div className="overflow-hidden bg-white font-bold shadow sm:rounded-lg">
        {/* <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Applicant Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
      </div> */}
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Referrals.YourReferralURL}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <p className='mb-6' id="referrals_code_p"></p>
                {/* <CopyToClipboard id="referrals_code_CopyToClipboard"> */}
                <CopyToClipboard text={this.state.value}
                  onCopy={() => this.setState({ copied: true })}>
                  <button className='flex mt-px-24 items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4'>
                    {LANGUAGE.Main.Referrals.Clipboard}
                  </button>
                </CopyToClipboard>

              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Referrals.ReferralHistory}</dt>
              {/* <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0"> */}
              <dd className="">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            User Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date Purchased
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Commission
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white" id="dataset">
                      </tbody>
                    </table>
                  </div>
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }
}

export default Example;
