
import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  KeyIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/outline'
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);
export function MenuContent () {
  const Obj = [
      { name: LANGUAGE.Menu.Personal, href: '/dashboard_personal', icon: HomeIcon, current: false },
      { name: LANGUAGE.Menu.ApiKey, href: '/dashboard_apikey', icon: UsersIcon, current: false },
      { name: LANGUAGE.Menu.Subscription, href: '/dashboard_subscription', icon: FolderIcon, current: true },
      { name: LANGUAGE.Menu.Referrals, href: '/dashboard_referrals', icon: CalendarIcon, current: false },
      { name: LANGUAGE.Menu.MFA, href: '/dashboard_mfa', icon: KeyIcon, current: false },
      { name: LANGUAGE.Menu.Additional, href: '/dashboard_additional', icon: Cog6ToothIcon, current: false },
    ]
  return Obj;
}
