import React from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Enum } from '../enum/enum';
import { Init } from '../language/login/Login_lang';
const LANGUAGE = Init(localStorage.lang);

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      open: false,
      msg: '',
    };
    this.Reload = this.Reload.bind(this);
    this.lsRememberMe = this.lsRememberMe.bind(this);
    this.OpenModalRef = this.OpenModalRef.bind(this);
    this.ClossModalRef = this.ClossModalRef.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.OpenModalLoad = this.OpenModalLoad.bind(this);
    this.ClossModalLoad = this.ClossModalLoad.bind(this);
    this.SendData = this.SendData.bind(this);
    this.GetData = this.GetData.bind(this);
    this.Reload();
  }
  async GetData() {

    await axios.get(Enum().url.IsLogin, '')
      .then((res) => {
        let ResponseData = JSON.parse(res.data);
        if (ResponseData.Result) {
          document.location.href = "/dashboard_personal";
        }
        const rmCheck = document.getElementById("remember-me"),
          emailInput = document.getElementById("email");

        if (localStorage.checkbox && localStorage.checkbox !== "") {
          rmCheck.setAttribute("checked", "checked");
          emailInput.value = localStorage.username;
        } else {
          rmCheck.removeAttribute("checked");
          emailInput.value = "";
        }
      })
      .catch(err => {
        // console.error(err);
      });
  };
  async SendData(data) {
    this.lsRememberMe()
    await this.OpenModalLoad();
    await axios.post('/sign_in/', data)
      .then(async (res) => {
        await this.ClossModalLoad();
        let _ResponseData = JSON.parse(res.data);
        if (_ResponseData.Result) {
          if(_ResponseData.Enable_MFA){
            document.location.href = Enum().url.MFA_login;
          }
          else{
            localStorage.login_status = 1;
            document.location.href = "/dashboard_personal";
          }
        }
        else {
          await this.OpenModalRef(_ResponseData.ErrorMsg);
        }
      })
      .catch(err => {
        // console.error(err);
      });
  };
  async OpenModalLoad() {
    this.setState({ load: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModalLoad() {
    this.setState({ load: false });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async OpenModalRef(msg) {
    this.setState({ msg: msg, open: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModalRef() {
    this.setState({ open: false, msg: '' });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async handleSubmit(e) {
    e.preventDefault();
    let IsSend = true;
    const Email = document.getElementById('email').value;
    const Password = document.getElementById('password').value;
    const FormData = {
      Email: Email,
      PassWord: Password,
    };
    for (var prop in FormData) {
      FormData[prop] = FormData[prop].trim();
    };
    if (IsSend) {
      await this.SendData(FormData);
    };
  };
  lsRememberMe() {
    const rmCheck = document.getElementById("remember-me"),
      emailInput = document.getElementById("email");
    if (rmCheck.checked && emailInput.value !== "") {
      localStorage.username = emailInput.value;
      localStorage.checkbox = rmCheck.value;
    } else {
      localStorage.username = "";
      localStorage.checkbox = "";
    }
  }
  Reload() {
    this.GetData();
  };
  render() {
    return (
      <div className="flex flex-col h-screen sm:px-6 lg:px-8 lg:my-8" >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={this.handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Login.Main.Email}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={this.handleInputChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-regal-blue focus:outline-none focus:ring-regal-blue sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                {LANGUAGE.Login.Main.Password}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={this.handleInputChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-regal-blue focus:outline-none focus:ring-regal-blue sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    onClick={this.lsRememberMe}
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  {LANGUAGE.Login.Main.Remember}
                  </label>
                </div>

                <div className="text-sm">
                  <a href="/passwordForget" className="font-medium text-regal-blue hover:text-black">
                  {LANGUAGE.Login.Main.Forget}?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  href="/dashboard_personal"
                  className="flex w-full justify-center rounded-md border border-transparent bg-regal-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-regal-blue focus:ring-offset-2"
                >
                  {LANGUAGE.Login.Button.SingIn}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">{LANGUAGE.Login.Button.Or}</span>
                </div>
              </div>
              <div className="mt-6">
                <a
                  href="/registration"
                  type="button"
                  className="flex w-full justify-center rounded-md border border-transparent bg-black py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-regal-blue focus:outline-none focus:ring-2 focus:ring-regal-blue focus:ring-offset-2"
                >
                  {LANGUAGE.Login.Button.SingUp}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={this.myRef} onClose={this.ClossModalRef}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Error
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500" id="modalText">
                            {/* Please check your input Information. */}
                            {this.state.msg}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={this.ClossModalRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={this.state.load} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={this.RefLoad} onClose={this.ClossModalLoad}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                        <div role="status">
                          <svg class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Loading...
                        </Dialog.Title>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      // </>
    )
  }
}

export default Example;
