import { CheckIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Enum } from '../enum/enum';
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.CancelPaymnet = this.CancelPaymnet.bind(this);
  };
  CancelPaymnet() {
    window.opener = null;
    window.open('', '_self');
    window.close();
  }
  render() {
    return (
      <div className="bg-white h-screen">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-36 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <div className="block py-5">
              <div className="relative flex items-center justify-center">
                <span
                  className='bg-green-500
                      h-24 w-24 rounded-full flex items-center justify-center ring-8 ring-white'
                >
                  <CheckIcon className="h-12 w-12 text-white" aria-hidden="true" />
                </span>
              </div>
            </div>
            <span className="block my-6">Your Purchase is Confirmed</span>
            <p className="text-xl mt-8">
              Please note that your credit card will be charged in New Taiwan Dollars ("USD") equivalent of U.S. Dollars as our credit card

            </p>
            <p className="text-xl">
              payment processor CyberSource is a Taiwanese financial institution regulated by Taiwanese financial authorities and regulators.
            </p>
          </h2>
          <div className="mt-12 flex justify-center">
            <div className="inline-flex rounded-md shadow mx-3">
              <a
                id="newebRender"
                href={Enum().url.purchase_success}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-5 py-3 text-base font-medium text-white hover:bg-green-500"
              >
                Confirmed
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Example;
