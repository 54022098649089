export function Config() {
    const Obj = {
        Setting: {
            Toggle: {
                AboutUs: {
                    Name:"AboutUs",
                    Value:true,
                    Href:"https://www.algodojo.com/#section-about",
                    Target:{
                        Value:false
                    }
                },
                Template: {
                    Name:"Template",
                    Value:false,
                    Href:"https://www.algodojo.com/#section-about",
                    Target:{
                        Value:false
                    }
                },
                Docs: {
                    Name:"Docs",
                    Value:true,
                    Href:"https://docs.algodojo.com/#introduction",
                    Target:{
                        Value:true
                    }
                },
                Pricing: {
                    Name:"Pricing",
                    Value:true,
                    Href:"https://www.algodojo.com#section-price",
                    Target:{
                        Value:false
                    }
                },
                Faq: {
                    Name:"Faq",
                    Value:true,
                    Href:"/faq",
                    Target:{
                        Value:true
                    }
                },
                Contact: {
                    Name:"Contact",
                    Value:true,
                    Href:"#section-contact",
                    Target:{
                        Value:false
                    }
                },

            }
        }
    };
    return Obj;
};

