export function Enum () {
    const Obj = {
        url:{
            paymentServer:'https://payment.algodojo.com/payment',
            // payment:'/payment', //for test
            payment:'/cs/subscription',
            purchase_success:'/purchase_success',
            purchase_fail:'/purchase_fail',
            transaction:'/payment/transaction',
            registerServer:'/payment/getToken/',
            getToken:'/payment/getToken/',            
            get_subdata:'/cs/get_subdata/',            
            register_payment:'/register_payment',            
            cancel_subscription:'/cs/cancel_subscription',            
            IsLogin:'/dashborad/isLogin/',            
            get_country_list:'/api/get_country_list',
            send_sms:'/verify_sms/send_sms',
            singout:'/sign_out',
            purchase_agreen:'/purchase_agreen',
            db_referrals:'dashborad/db_referrals',
            getReferralsData:'referrals/getReferralsData',
            db_personal:'dashborad/db_personal',
            db_api:'dashborad/db_api',
            db_api_generate:'dashborad/db_api_generate',
            db_subscription:'dashborad/db_subscription',
            db_mfa:"dashboard_mfa",
            referralsUrl:'https://www.algodojo.com/registration?referrals=',
            getTemplateList:'/template/getTemplateList',
            getTemplate:'/template/getTemplate',
            MFA_generate:'/mfa/mfa_generate',
            MFA_verify:'/sign_in/mfa_verify',
            MFA_login:'/login_mfa',
            MFA_isEnable:'/mfa/isEnable',
            MFA_get_MFA:'/mfa/get_MFA',
            MFA_disable:'/mfa/disable',
            MFA_signup_verify:'/mfa/mfa_verify',
            MFA_getBackUpCode:'/mfa/getBackUpCode',
            MFA_enable_frontend:'/mfa_enable',
            MFA_enable:'/mfa/enable',
            MFA_enable_verify:'/mfa/enable_verify',
        }
    };
    return Obj;
};

