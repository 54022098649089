import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XCircleIcon, ExclamationTriangleIcon, CreditCardIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const tabs = [
  { name: '', href: '#', current: false },
  { name: 'Monthly Billing', href: '#', current: true },
  { name: 'Billed annually(save up to 15%)', href: '#', current: false },
  { name: '', href: '#', current: false },
]
let payment_url = Enum().url.payment;
let back = false;
let token = null;
let ALL_Price = 0;
let DJIA_Price = 0;
let ALL_NumberMonths = 0;
let DJIA_NumberMonths = 0;
let ALL_SaleRate = 0;
let DJIA_SaleRate = 0;
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan_type: false,
      silver_plan: false,
      gold_plan: false,
      href_silver_plan: null,
      href_gold_plan: null,
      selectedDeliveryMethod: false,
      referrals_mark: '                                   ',
      open: false,
      load: false,
      day: 0,
      show_silver: true,
      show_gold: true,
      show_free: false,
      show_free_btn: false,
      show_renew: false,
      origin_price: 0,
      offset: 0,
      status: false,
      choose_open: false,
      submit_href: null,
      submit_type: null,
      cardType: 'usd'
    };
    this.TogglePlanHandlerTrue = this.TogglePlanHandlerTrue.bind(this);
    this.TogglePlanHandlerFalse = this.TogglePlanHandlerFalse.bind(this);
    this.ChangePlan = this.ChangePlan.bind(this);
    this.ChangeCardType = this.ChangeCardType.bind(this);
    this.GetData = this.GetData.bind(this);
    this.InitBtn = this.InitBtn.bind(this);
    this.RederPayment = this.RederPayment.bind(this);
    this.Confirm_PaymentCard_Type = this.Confirm_PaymentCard_Type.bind(this);
    this.setSelectedDeliveryMethod = this.setSelectedDeliveryMethod.bind(this);
    this.OpenModal = this.OpenModal.bind(this);
    this.ClossModal = this.ClossModal.bind(this);
    this.handleSubmit_confirm = this.handleSubmit_confirm.bind(this);
    this.OpenChooseModal = this.OpenChooseModal.bind(this);
    this.ClossChooseModal = this.ClossChooseModal.bind(this);
    this.handleSubmit_choose_confirm = this.handleSubmit_choose_confirm.bind(this);
  }
  setSelectedDeliveryMethod() {
    this.state.selectedDeliveryMethod = true;
    // console.log('this.state.selectedDeliveryMethod', this.state.selectedDeliveryMethod);
  }
  componentDidMount() {
    this.InitBtn();
    this.GetSubData();
    this.GetData();
  }
  async InitBtn() {
    const urlParams = new URLSearchParams(window.location.search);
    var type = urlParams.get('type');
    var action = urlParams.get('action');
    var show_free_btn = urlParams.get('show_free_btn');
    if (type == "Silver") {
      if (action == 'Upgrade') {
        this.setState((state) => ({ show_silver: false }));
      } else {
        this.setState((state) => ({ show_gold: false, show_renew: true }));
      }
    }
    else if (type == "Gold") {
      this.setState((state) => ({ show_silver: false, show_renew: true }));
    }
    else {
      this.setState((state) => ({ show_free: true }));
    }
    if (Boolean(parseInt(show_free_btn))) {
      this.setState((state) => ({ show_free_btn: true }));
    }
  }
  async GetData() {
    await axios.post(Enum().url.getToken, '')
      .then((res) => {
        let ResponeData = JSON.parse(res.data);
        // console.log('_TokenData',ResponeData);
        if (ResponeData.Result) {
          // console.log(ResponeData.Data);
          back = true;
          token = ResponeData.Data.token;
          const isReferralsUse = ResponeData.Data.isReferralsUsed;
          // alert("isReferralsUsed"+isReferralsUsed);
          const SubscriptionData = ResponeData.Data.subscriptionData;
          if (isReferralsUse) {
            this.setState({ referrals_mark: 'You have a discount on the price because you have a referrals code' });
          }
          SubscriptionData.forEach(element => {
            if (element.name == 'ALL') {
              ALL_Price = element.price;
              ALL_NumberMonths = element.numberMonths;
              ALL_SaleRate = element.saleRate;
            }
            else {
              DJIA_Price = element.price;
              DJIA_NumberMonths = element.numberMonths;
              DJIA_SaleRate = element.saleRate;
            }
          });

          if (this.state.show_silver) {
            this.state.href_silver_plan = payment_url + "?plan=1&token=" + token + "&price=" + DJIA_Price;;

            document.getElementById('silver_price').innerHTML = "US$ " + DJIA_Price.toString();
            document.getElementById('silver_frequency').innerHTML = "/MONTH";
          };

          if (this.state.show_gold) {
            this.setState((state) => ({ origin_price: ALL_Price }));
            this.state.href_gold_plan = payment_url + "?plan=3&token=" + token + "&price=" + ALL_Price;;

            document.getElementById('gold_price').innerHTML = "US$ " + ALL_Price.toString();
            document.getElementById('gold_frequency').innerHTML = "/MONTH";
          };
        }
        else {
          alert("server error please contact us.");
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  async GetSubData() {
    await axios.post(Enum().url.get_subdata, '')
      .then((res) => {
        let ResponeData = res.data;
        console.log('GetSubData_ResponeData', ResponeData);
        this.setState((state) => ({
          status: ResponeData.status,
          day: ResponeData.day,
          offset: ResponeData.offset
        }));
      })
      .catch(err => {
        console.error(err);
      });
  };
  TogglePlanHandlerFalse() {
    this.setState((state) => ({ plan_type: false }));
    this.setState((state) => ({ silver_plan: false }));
    this.setState((state) => ({ gold_plan: false }));
    this.ChangePlan();
  }
  TogglePlanHandlerTrue() {
    this.setState((state) => ({ plan_type: true }));
    this.setState((state) => ({ silver_plan: true }));
    this.setState((state) => ({ gold_plan: true }));
    this.ChangePlan();
  };
  ChangePlan() {
    if (this.state.plan_type) {
      if (token == null) {
        alert('please wait five sec.');
      };

      if (this.state.show_silver) {
        this.state.href_silver_plan = payment_url + "?plan=1&token=" + token + "&price=" + DJIA_Price + "&upgrade=0";

        document.getElementById('silver_price').innerHTML = "US$ " + DJIA_Price.toString();
        document.getElementById('silver_price_bottom').innerHTML = "";

        document.getElementById('silver_frequency_bottom').innerHTML = "";
        document.getElementById('silver_frequency').innerHTML = "/MONTH";
      }

      if (this.state.show_gold) {
        this.setState((state) => ({ origin_price: ALL_Price }));
        this.state.href_gold_plan = payment_url + "?plan=3&token=" + token + token + "&price=" + ALL_Price + "&upgrade=0";

        document.getElementById('gold_price').innerHTML = "US$ " + ALL_Price.toString();
        document.getElementById('gold_frequency').innerHTML = "/MONTH";
        document.getElementById('gold_price_bottom').innerHTML = "";
        document.getElementById('gold_frequency_bottom').innerHTML = "";

      }
    }
    else {
      if (token == null) {
        alert('please wait five sec.');
      };

      if (this.state.show_silver) {
        this.state.href_silver_plan = payment_url + "?plan=2&token=" + token + "&price=" + DJIA_Price * DJIA_NumberMonths * DJIA_SaleRate + "&upgrade=0";

        document.getElementById('silver_price').innerHTML = "US$ " + parseInt(DJIA_Price * DJIA_NumberMonths * DJIA_SaleRate).toString();
        document.getElementById('silver_frequency').innerHTML = "/YEAR";
        document.getElementById('silver_price_bottom').innerHTML = "US$ " + parseInt(DJIA_Price * DJIA_NumberMonths).toString();
        document.getElementById('silver_frequency_bottom').innerHTML = "/YEAR";
      };

      if (this.state.show_gold) {
        this.setState((state) => ({ origin_price: parseInt(ALL_Price * ALL_NumberMonths * ALL_SaleRate) }));
        this.state.href_gold_plan = payment_url + "?plan=4&token=" + token + "&price=" + ALL_Price * ALL_NumberMonths * ALL_SaleRate + "&upgrade=0";

        document.getElementById('gold_price').innerHTML = "US$ " + parseInt(ALL_Price * ALL_NumberMonths * ALL_SaleRate).toString();
        document.getElementById('gold_frequency').innerHTML = "/YEAR";
        document.getElementById('gold_price_bottom').innerHTML = "US$ " + parseInt(ALL_Price * ALL_NumberMonths).toString();
        document.getElementById('gold_frequency_bottom').innerHTML = "/YEAR";
      };
    }
  };
  ChangeCardType() {
    if(this.state.cardType == 'usd'){
      this.setState((state) => ({ cardType: 'twd' }));
    }
    else{
      this.setState((state) => ({ cardType: 'usd' }));
    }
  };
  async Confirm_PaymentCard_Type(href, plan_type) {
    this.state.submit_href = href;
    this.state.submit_type = plan_type;
    this.OpenChooseModal()
    // alert("The system is undergoing maintenance.");
  }
  async RederPayment(href, plan_type) {
    if (plan_type == 'gold') {
      if (this.state.status) {
        await this.OpenModal();
      }
      else {
        window.location.replace(href)
      }
    } else {
      window.location.replace(href)
    }
  };
  async OpenModal() {
    this.setState({ open: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModal() {
    this.setState({ open: false });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async handleSubmit_confirm() {
    await this.ClossModal();
    const url = this.state.href_gold_plan + "&upgrade=1";
    window.location.replace(url);
  }

  async OpenChooseModal() {
    this.setState({ choose_open: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossChooseModal() {
    this.setState({ choose_open: false });
    await new Promise(resolve => setTimeout(resolve, 500));
  };

  async handleSubmit_choose_confirm() {
    await this.ClossChooseModal();
    this.state.submit_href = this.state.submit_href + '&currency=' + this.state.cardType;
    this.RederPayment(this.state.submit_href, this.state.submit_type);
  }
  render() {
    return (
      // <div className="bg-white">
      <div className="bg-white screen">
        <div className="mx-auto  h-sreen max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">{this.state.show_renew ? "Please Choose a Billing Cycle" : "Please Choose a Plan?"}</span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md mx-auto w-1/2">
              <div className="w-full">
                <div>
                  <div className="hidden sm:block">
                    <nav className="isolate flex divide-x divide-gray-200 rounded-tl-lg rounded-tr-lg " aria-label="Tabs">
                      <a className='group relative min-w-0 flex-1 overflow-hidden bg-white border-b-2 text-sm font-medium text-center focus:z-10'>
                        <span></span>
                        <span></span>
                      </a>
                      <a
                        onClick={this.TogglePlanHandlerFalse}
                        className={classNames(
                          this.state.plan_type ? 'text-gray-500 hover:text-gray-700' : 'text-gray-900',
                          this.state.plan_type ? 'border-2 border-gray-400' : 'border-t-2 border-gray-400',
                          'py-2 group relative min-w-0 flex-1 overflow-hidden bg-white text-sm font-medium text-center hover:bg-gray-50 focus:z-10 rounded-t-lg'
                        )}
                        aria-current={this.state.plan_type ? undefined : 'page'}
                      >
                        <span className='font-bold'>Billed</span><br></br>
                        <span className='font-bold'>Monthly</span>

                      </a>
                      <a
                        onClick={this.TogglePlanHandlerTrue}
                        className={classNames(
                          this.state.plan_type ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                          this.state.plan_type ? 'border-t-2' : 'border-2',
                          'py-2 group relative min-w-0 flex-1 overflow-hidden bg-white  text-sm font-medium text-center hover:bg-gray-50 focus:z-10 rounded-t-lg'
                        )}
                        aria-current={this.state.plan_type ? 'page' : undefined}
                      >
                        <span className='font-bold'>Billed Annually</span><br></br>
                        <span className='font-bold'>(Save Up to 15%)</span>
                      </a>
                      <a className='group relative min-w-0 flex-1 overflow-hidden bg-white border-b-2  text-sm font-medium text-center focus:z-10'>
                        <span></span>
                        <span></span>
                      </a>
                    </nav>
                  </div>
                </div>
                <div className="mt-6">
                  <p className='text-red-600/100'>{this.state.referrals_mark}</p>
                </div>
                <div className={classNames(this.state.show_free ? "mt-6 space-y-4 my-16 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-2" : "mt-6 space-y-4 my-16 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-1")}>
                  {this.state.show_silver && (
                    <div className="divide-y divide-gray-200 shadow-sm text-center">
                      <span className="text-2xl font-bold text-black mb-16">SILVER PLAN</span>

                      <a id="plan1_href" onClick={() => this.Confirm_PaymentCard_Type(this.state.href_silver_plan, 'silver')}>
                        <div className="p-6 bg-regal-blue">
                          <p className="mt-5">
                            <span className={classNames(
                              this.state.silver_plan ? "text-white" : "text-white",
                              // this.state.plan1 ? "text-regal-gray" : "text-white",
                              "text-4xl font-bold tracking-tight my-5")} id="silver_price">US$ 0</span>{' '}
                            <span className={classNames(
                              this.state.silver_plan ? "text-white" : "text-white",
                              // this.state.plan1 ? "text-regal-gray" : "text-white",
                              "text-base font-medium")} id="silver_frequency">/MONTH</span>
                          </p>

                          <p className="mt-5">
                            <s>
                              <span className={classNames(
                                this.state.silver_plan ? "text-white" : "text-white",
                                "text-2xl font-bold tracking-tight")} id="silver_price_bottom"></span>{' '}
                            </s>
                            <span className={classNames(
                              // this.state.plan1 ? "text-regal-gray" : "text-white",
                              this.state.silver_plan ? "text-white" : "text-white",
                              "text-base font-medium my-5")} id="silver_frequency_bottom"></span>
                          </p>
                        </div>
                      </a>
                    </div>)}
                  {this.state.show_gold && (
                    <div className="divide-y divide-gray-200 shadow-sm text-center">
                      {/* <div> */}
                      {/* <span className="text-3xl font-medium text-black mb-16">GOLD PLAN</span> */}
                      <span className="text-2xl font-bold text-black mb-16">GOLD PLAN</span>
                      {/* <div/> */}
                      {/* <a id="plan2_href" onClick={() => this.RederPayment(this.state.href_gold_plan, 'gold')}> */}
                      <a id="plan2_href" onClick={() => this.Confirm_PaymentCard_Type(this.state.href_gold_plan, 'gold')}>
                        {/* <a id="plan2_href" href={Enum().url.purchase_agreen} onClick={() => this.RederPayment(this.state.HrefPlan2)}> */}
                        <div className="p-6 bg-regal-blue">
                          <p className="mt-5">
                            <span className={classNames(
                              this.state.gold_plan ? "text-white" : "text-white",
                              "text-4xl font-bold tracking-tight my-5")} id="gold_price">US$ 0</span>{' '}
                            <span className={classNames(
                              this.state.gold_plan ? "text-white" : "text-white",
                              "text-base font-medium")} id="gold_frequency">/MONTH</span>
                          </p>

                          <p className="mt-5">
                            <s>
                              <span className={classNames(
                                this.state.gold_plan ? "text-white" : "text-white",
                                "text-2xl font-bold tracking-tight")} id="gold_price_bottom"></span>{' '}
                            </s>
                            <span className={classNames(
                              // this.state.plan1 ? "text-regal-gray" : "text-white",
                              this.state.gold_plan ? "text-white" : "text-white",
                              "text-base font-medium my-5")} id="gold_frequency_bottom"></span>
                          </p>

                        </div>
                      </a>
                    </div>)}
                </div>
              </div>
            </div>
          </div>
          {this.state.show_free_btn && (
            <div className="inline-flex pt-5">
              <a
                href="https://docs.algodojo.com/#introduction"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-black px-5 py-3 text-base font-medium text-white hover:bg-regal-blue"
              >
                I'll start with 30 day free trial
              </a>
            </div>
          )}
        </div>


        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.state.open}
            onClose={this.ClossModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Thank you for choosing our services and pgrading your subscription plan
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500" id="modalText">
                            You currently have <strong>  {this.state.day} </strong> days remaining on your Silver Plan.
                          </p>
                          <p className="text-sm text-gray-500" id="modalText">
                            When you upgrade from Silver to Gold, your remaining unused credit will be applied to your new subscription and deducted from your first month's payment for the new plan.
                          </p>
                          <p className="text-sm text-gray-500" id="modalText">
                            Your will be charged  <strong> ${(this.state.origin_price - this.state.offset)} </strong>  for the first {this.state.plan_type ? "year" : "month"} and <strong>  ${this.state.origin_price} </strong>  for each following {this.state.plan_type ? "year" : "month"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        // onClick={this.ShowModalRef}
                        onClick={this.ClossModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="mx-4 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        // onClick={this.ShowModalRef}
                        onClick={this.handleSubmit_confirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={this.state.choose_open} as={Fragment}>
          <Dialog
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.state.choose_open}
            onClose={this.ClossChooseModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CreditCardIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Select Credit Card Issuing Country
                        </Dialog.Title>
                        <div className="mt-2">
                          <label className="text-sm text-gray-500">
                            <input
                              type="radio"
                              name="cardType"
                              value="twd"
                              checked={this.state.cardType == 'twd'}
                              // onChange={this.state.cardType = 'twd'}
                              onChange={this.ChangeCardType}
                              // onChange={(e) => this.setCardType(e.target.value)}
                              className="mr-2"
                            />
                            Taiwan Credit Card 
                          </label>
                          <br />
                          <label className="text-sm text-gray-500">
                            <input
                              type="radio"
                              name="cardType"
                              value="usd"
                              checked={this.state.cardType == 'usd'}
                              // onChange={this.state.cardType = 'usd'}
                              onChange={this.ChangeCardType}
                              // onChange={this.setCardType(e.target.value)}
                              className="mr-2"
                            />
                            Non-Taiwan Credit Card
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        // onClick={this.ShowModalRef}
                        onClick={this.ClossChooseModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="mx-4 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        // onClick={this.ShowModalRef}
                        onClick={this.handleSubmit_choose_confirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>

    )
  }
}
export default Example;
