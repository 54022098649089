export function Init(type) {
    const CN = {
        Menu: {
            Personal: '個人資料',
            ApiKey: 'API金鑰',
            Subscription: '訂閱資料',
            Referrals: '分享碼',
            MFA: 'MFA',
            Additional: '設定'
        },
        Main: {
            Personal: {
                Email: '信箱',
                Mobile: '手機',
                ChangePW: '更改密碼'
            },
            ApiKey: {
                CurrentKEY: '目前KEY',
                GetNewKEY: '取得新API KEY',
                GetNewKEYObj: {
                    Confirm: '您確定要創建新API KEY嗎？ 我們將永久刪除舊API KEY並且無法使用它，此操作無法撤消。',
                    Generate: '產生',
                    Cancel: '取消',
                    Attention: '注意'
                }
            },
            Subscription: {
                StartDate: '使用起日',
                EndDate: '使用迄日',
                DataSet: {
                    Title: '數據',
                    Plan: '計畫',
                    DataSet: '數據'
                },
                Upgrade: '升級',
            },
            Referrals: {
                YourReferralURL: "你的分享碼連結",
                ReferralHistory: "分享紀錄",
                Clipboard: "複製分享碼"
            },
            MFA:{
                TurnOn:"開啟"
            }
        },
        PersonalMenu: {
            SignOut: '登出'
        }
    };
    const EN = {
        Menu: {
            Personal: 'Personal Info',
            ApiKey: 'API KEY',
            Subscription: 'Subscription',
            Referrals: 'Referrals',
            MFA: 'MFA',
            Additional: 'Additional Setting'
        },
        Main: {
            Personal: {
                Email: 'Email',
                Mobile: 'Mobile',
                ChangePW: 'Change Password'
            },
            ApiKey: {
                CurrentKEY: 'Current KEY',
                GetNewKEY: 'Get New API KEY',
                GetNewKEYObj: {
                    Confirm: 'Are you sure you want to create a new token? We will permanently delete the old token and cannot use it, this action cannot be undone.',
                    Generate: 'Generate',
                    Cancel: 'Cancel',
                    Attention: 'Attention'
                }
            },
            Subscription: {
                StartDate: 'Date Purchased',
                EndDate: 'Date License Expires',
                DataSet: {
                    Title: 'Data Set',
                    Plan: 'Plan',
                    DataSet: 'Data Set'
                },
                Upgrade: 'Upgrade',
            }, 
            Referrals: {
                YourReferralURL: "Your Referral URL",
                ReferralHistory: "Referral History",
                Clipboard: "Copy to clipboard"
            },
            MFA:{
                TurnOn:"Turn On"
            }
            

        },
        PersonalMenu: {
            SignOut: 'Sign out'
        }
    };
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

