import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);
const HREF = Enum().url.register_payment;
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btn_text: "Subscribe",
      showButton: false,
      href: HREF + '?type=Free',
      end_day: null
    };
    this.GetData = this.GetData.bind(this);
    this.Change_btn = this.Change_btn.bind(this);
    this.OpenModal = this.OpenModal.bind(this);
    this.ClossModal = this.ClossModal.bind(this);
  };
  componentDidMount() {
    this.GetData();
  };
  async OpenModal() {
    this.setState({ open: true });
  };
  async ClossModal() {
    this.setState({ open: false });
  };
  async Change_btn(name) {
    let newBtnText = "";
    let BtnURL = HREF;
    if (name == "FREE") {
      newBtnText = "Subscribe";
      BtnURL = BtnURL + '?type=Free';
    }
    else if (name == "Silver") {
      newBtnText = "Upgrade To Gold";
      BtnURL = BtnURL + '?type=Silver&action=Upgrade';
      this.setState({ showButton: true });
    }
    else {
      BtnURL = BtnURL + '?type=Gold&action=Renew';
      newBtnText = "Renew";
    }

    if (new Date() > new Date(this.state.end_day)) {
      BtnURL = BtnURL + '&show_free_btn=0';
    } else {
      BtnURL = BtnURL + '&show_free_btn=1';
    };

    this.setState({ btn_text: newBtnText });
    this.setState({ href: BtnURL });
  };
  async GetData() {
    await axios.post(Enum().url.db_subscription, '')
      .then(async (res) => {
        let responcessData = JSON.parse(res.data);
        responcessData = responcessData.Data;
        // console.log('responcessData', responcessData);
        const end_day = responcessData.endDate;
        document.getElementById('startDate').innerHTML = responcessData.startDate;
        document.getElementById('endDate').innerHTML = end_day;
        this.setState({ endDate: end_day });
        let personIdx = 0;

        for (let index = 0; index < responcessData.DataSet.length; index++) {
          const element = responcessData.DataSet[index];
          let Name = element.name;
          let Setname = element.Setname;
          await this.Change_btn(Name);

          var _TrElement = document.createElement("tr");
          var class_tr = document.createAttribute('class');
          class_tr.value = (personIdx % 2 === 0 ? undefined : 'bg-gray-50');
          _TrElement.setAttributeNode(class_tr);

          var _TdElement1 = document.createElement("td");
          var class1 = document.createAttribute('class');
          class1.value = "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6";
          _TdElement1.setAttributeNode(class1);

          var _TdElement2 = document.createElement("td");
          var class2 = document.createAttribute('class');
          class2.value = "whitespace-nowrap px-3 py-4 text-sm text-gray-500";
          _TdElement2.setAttributeNode(class2);

          var text = document.createTextNode(Name);
          _TdElement1.appendChild(text);
          _TrElement.appendChild(_TdElement1);
          if(new Date() > new Date(end_day) && Name == "FREE"){
            Setname = "Bring your own data";
          }
          text = document.createTextNode(Setname);
          _TdElement2.appendChild(text);
          _TrElement.appendChild(_TdElement2);

          document.getElementById('dataset').appendChild(_TrElement);
          personIdx = personIdx + 1;
        };
      })
      .catch(err => {
        console.error(err);
      });
  };
  render() {
    return (
      <div className="overflow-hidden bg-white font-bold shadow sm:rounded-lg">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Subscription.StartDate}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="startDate"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Subscription.EndDate}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="endDate"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Subscription.DataSet.Title}</dt>
              <dd className="">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            {LANGUAGE.Main.Subscription.DataSet.Plan}
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {LANGUAGE.Main.Subscription.DataSet.DataSet}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white" id="dataset">
                      </tbody>
                    </table>
                  </div>

                </div>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500"></dt>
              <dd className="">
                <a
                  href={this.state.href}
                  target="_blank"
                  className="flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4"
                >
                  {this.state.btn_text}
                </a>
              </dd>
              {this.state.showButton && (
                <dd className="">
                  <a
                    href={HREF + "?type=Silver&action=Renew"}
                    target="_blank"
                    className="flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4"
                  >
                    Renew
                  </a>
                </dd>
              )}
            </div>
          </dl>
        </div>
      </div>
    )
  }
}

export default Example;
