const youtubeEmbedUrl = "https://www.youtube.com/embed/Zspq91D0AiU?si=vnFmBX-tQzRqFBGb";

export default function Example() {
  return (
    <div className="bg-regal-gray">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-8 sm:space-y-12">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-gray-400"></h2>
          </div>
          <div
            role="list"
            className="mx-auto grid grid-cols-1 gap-x-4 grid-rows-3 gap-y-8 sm:grid-cols-1 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-1"
          >
            {/* Center the iframe using a div with display flex and justify-content center */}
            <div className="flex justify-center items-center row-span-3">
              <iframe
                width="560"
                height="315"
                src={youtubeEmbedUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
