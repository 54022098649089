export function Init(type) {
    const CN = {
        Main:{
            Tiers_1:{
                Title:"三十天免費使用歷史數據資料庫與API",
                Content1:'',
                Content2:''

            },
            Tiers_2:{
                Title:"使用同一個程式碼進行回溯測試與交易"
            },
            Tiers_3:{
                Title:'使用機構等級的日內歷史數據'
            }
        },
        Button:{
            Content:'免費試用'
        }
    };
    const EN = {
        Main:{
            Tiers_1:{
                Title:"FREE TRIAL ACCESS TO HISTORICAL DATA & APIs FOR 30 DAYS",
                Content1:'',
                Content2:''

            },
            Tiers_2:{
                Title:"USE SAME LINE OF CODE FOR BACKTESTING & LIVE TRADING"
            },
            Tiers_3:{
                Title:'ACCESS TO INSTITUTIONAL GRADE, INTRADAY HISTORICAL TRADING DATA'
            }
        },
        Button:{
            Content:'FREE TRIAL'
        }
    };
    // return EN;
    if(type == 'cn'){
        return CN;
    }
    else{
        return EN;
    }
};

