/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Init } from '../language/home/Menu_lang';
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  GlobeAltIcon
} from '@heroicons/react/24/outline'
import { Config } from '../enum/config';
import GoogleTagManager from '../google/google'
const LANGUAGE = Init(localStorage.lang);

const navigation = [];
function GetNavigation() {
  const ToggleData = Object.values(Config().Setting.Toggle);
  for (let index = 0; index < ToggleData.length; index++) {
    const element = ToggleData[index];
    const Name = LANGUAGE.Menu[element.Name];
    const Href = element.Href;
    const Target = element.Target.Value;
    const ToggleValue = element.Value;
    if (ToggleValue) {
      const navigation_element = {
        name: Name,
        href: Href,
      }
      if (Target) {
        navigation_element["target"] = "_blank";
      }
      navigation.push(navigation_element);
    }
  }
}
GetNavigation();

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.Reload = this.Reload.bind(this);
    this.SetLang_EN = this.SetLang_EN.bind(this);
    this.SetLang_CN = this.SetLang_CN.bind(this);
    this.SetMLang = this.SetMLang.bind(this);
  };
  Reload() {
    if (localStorage.login_status == 1) {
      document.getElementById("login_bt").innerHTML = LANGUAGE.MenuLogin.MyAccount;
      document.getElementById("login_href").href = '/dashboard_personal';
    }
    else {
      document.getElementById("login_bt").innerHTML = LANGUAGE.MenuLogin.Login;
      document.getElementById("login_href").href = '/login';
    }
    if (localStorage.lang && localStorage.lang !== "") {
      // document.getElementById("lang").value = localStorage.lang;

      // if(localStorage.lang == "cn"){
      //   console.log('localStorage.lang == "cn"');
      //   document.getElementById("lang_cn").innerHTML = "中文";
      // }
      // else{
      //   document.getElementById("lang_cn").innerHTML = "CN";
      // }
      document.getElementById("lang_mobile").value = localStorage.lang;
    }
    else {
      // document.getElementById("lang_cn").innerHTML = "CN";
    };
  };
  // SetLang() {
  //   const LangType = document.getElementById("lang").value;
  //   localStorage.lang = LangType;
  //   window.history.go(0);
  // }
  SetLang_EN() {
    localStorage.lang = 'en';
    window.history.go(0);
  }
  SetLang_CN() {
    localStorage.lang = 'cn';
    window.history.go(0);
  }
  SetMLang() {
    const LangType = document.getElementById("lang_mobile").value;
    localStorage.lang = LangType;
    window.history.go(0);
  }
  render() {
    return (
      <div className="mx-auto max-w-5xl" onLoad={this.Reload}>
        <GoogleTagManager></GoogleTagManager>
        <header>
          <Popover className="relative bg-regal-gray">
            <div className="mx-auto flex max-w-6xl items-center justify-between px-4 py-3 sm:px-6 md:justify-start md:space-x-10 lg:px-12  bg-regal-gray">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-14 mx-auto max-w-5xl sm:h-14"
                    src="images/logo.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="-my-2 -mr-2 md:hidden">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-regal-blue">
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden space-x-10 md:flex">

                {navigation.map((item) => (
                  <a key={item.name} href={item.href} target={item.target} className="text-sm font-bold text-white hover:text-regal-blue">
                    {item.name}
                  </a>
                ))}
              </Popover.Group>

              <div className="hidden items-center justify-end md:flex md:flex-1">
                <a
                  href="/login"
                  id="login_href"
                  className="font-bold inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-regal-gray px-4 py-2 text-sm text-white shadow-sm hover:text-regal-blue"
                >
                  <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                  <span> &nbsp;</span>
                  <span id="login_bt"></span>
                </a>

                <div>
                  <span className={classNames(localStorage.lang == 'cn' ? 'text-white' : 'text-regal-blue',
                    "font-bold rounded-md border border-transparent text-white shadow hover:text-regal-blue")
                  } onClick={this.SetLang_EN}>EN</span>
                  <span className="font-bold rounded-md border border-transparent text-white shadow">/</span>
                  <span id="lang_cn" className={classNames(localStorage.lang == 'cn' ? 'text-regal-blue' : 'text-white',
                    "font-bold rounded-md border border-transparent text-white shadow hover:text-regal-blue")}
                    onClick={this.SetLang_CN}>繁中</span>
                </div>
                {/* <div className='flex w-24'>
                  <select
                    id="lang"
                    name="lang"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-regal-blue focus:outline-none focus:regal-blue sm:text-sm"
                    defaultValue="Canada"
                    onChange={this.SetLang}
                  >
                    <option value="en" select="selected">EN</option>
                    <option value="cn">CN</option>
                  </select>
                </div> */}

              </div>

            </div>

            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="px-5 pt-5 pb-6">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="images/logo.png"
                          alt="Your Company"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-regal-blue focus:outline-none focus:ring-2 focus:ring-inset focus:ring-regal-blue">
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="py-6 px-5">
                    <div className="grid grid-cols-1 gap-4">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6">
                      <a
                        href="/login"
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-black"
                      >
                        {LANGUAGE.MenuLogin.Login}
                      </a>
                      <p className="mt-6 text-center text-base font-medium text-gray-500">
                        {LANGUAGE.MenuLogin.Join}?
                        <a href="/registration" className="text-gray-900 px-2">
                          {LANGUAGE.MenuLogin.SingUp}
                        </a>
                      </p>
                    </div>

                    <div>
                      <select
                        id="lang_mobile"
                        name="lang_mobile"
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-regal-blue focus:outline-none focus:regal-blue sm:text-sm"
                        defaultValue="Canada"
                        onChange={this.SetMLang}
                      >
                        <option value="en" select="selected">EN</option>
                        <option value="cn">CN</option>
                      </select>
                    </div>

                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </header>
      </div>
    )
  }
}

export default Example;
