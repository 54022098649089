/* This example requires Tailwind CSS v2.0+ */
import Slider from './Slider';
import Video from './Video';
import News from './News';
import About from './About';
import Project from './Project';
import Pricing from './Pricing';
import Service from './Service';

export default function Example() {
  return (
    <div>
      {/* <MenuModel></MenuModel> */}
      <Slider></Slider>
      <Video></Video>
      <Project></Project>
      <About></About>
      {/* <News></News> */}
      <Pricing></Pricing>
      <Service></Service>
      {/* <Contact></Contact> */}
      {/* <Footer></Footer> */}
    </div>
  )
}
