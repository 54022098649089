export function Init(type) {
    const CN = {
        Title:'建立你的AlgoDojo帳號',
        Form:{
            FirstName: '姓',
            LastName: '名',
            Email: '信箱',
            Password: '密碼',
            ConfirmPassword: '再次確認密碼',
            Country: '國家',
            PhoneNumber: '電話號碼',
            Agreen:{
                Content_1:'選擇此項，即表示您同意我們的',
                Policy:'隱私政策',
                Content_2:'與',
                Conditions:'條款和條件',
                Content_3:''
            }
        },
        CheckText:{
            PasswordMatch:'密碼符合',
            PasswordNotMatch:'密碼不符合',
            ConfirmPassword:'請檢查你的密碼',
            PasswordLen6:'密碼不得低於6位數或裡面要有最少一個大寫的字母',
            Phonenumber:'電話號碼格式不正確',
            Agreed:'沒有同意隱私政策與條款和條件',
            card_number:'信用卡卡號規格不符合',
            cvc:'cvc規格不符合',
            expirationDate:'有效日期規格不符合',
        },
        Button:'下一步'
    };
    const EN = {
        Title:'Create Your AlgoDojo Account',
        Form:{
            FirstName: 'First Name',
            LastName: 'Last Name',
            Email: 'Email',
            Password: 'Password',
            ConfirmPassword: 'Confirm Password',
            Country: 'Country',
            PhoneNumber: 'Phone Number',
            Agreen:{
                Content_1:'By selecting this, you agree to our',
                Policy:'Privacy Policy',
                Content_2:'and',
                Conditions:'Terms & Conditions',
                Content_3:''
            }
        },
        CheckText:{
            PasswordMatch:'Passwords match',
            PasswordNotMatch:'Passwords do not match',
            ConfirmPassword:'Please check your password.',
            PasswordLen6:'Password must be at least 6 characters long and include upper and lower case.',
            Phonenumber:'Phone number fortmat is wrong.',
            Agreed:'Not agreed Privacy Policy and Terms & Conditions.',
            card_number:'Card number fortmat is wrong.',
            cvc:'CVC fortmat is wrong.',
            expirationDate:'Expiration date fortmat is wrong.',
        },
        Button:'Next'
    };
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

