import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';

import { Init } from '../language/templates/templates_lang';
const TemplateName = "Scalping";

const LANGUAGE = Init(localStorage.lang);
let DownloadPath = null;
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      reports: [
        require(`../images/${props.TemplateName}_1.png`),
        require(`../images/${props.TemplateName}_2.png`),
        require(`../images/${props.TemplateName}_3.png`),
        require(`../images/${props.TemplateName}_4.png`),
        require(`../images/${props.TemplateName}_5.png`),
        require(`../images/${props.TemplateName}_6.png`),
      ]
    };
    this.Reload = this.Reload.bind(this);
    this.GetData = this.GetData.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.Reload = this.Reload.bind(this);
    this.Reload();
  };
  async GetData() {
    await axios.post(Enum().url.getTemplate, { name: TemplateName })
      .then((res) => {
        let _TemplateData = res.data.data;
        console.log('_TemplateData', _TemplateData);
        this.setState((state) => ({ description: _TemplateData.description }));
        if (_TemplateData.uuid != null) {
          DownloadPath = _TemplateData.uuid + ".py"
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  Reload() {
    console.log('reload');
    this.GetData();
  };
  Download() {
    fetch(DownloadPath)
      .then(response => response.blob())
      .then(blob => {
        const fileURL = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = DownloadPath;
        downloadLink.click();
      });
  }
  handleButtonClick() {
    if (DownloadPath != null) {
      this.Download()
    }
    else {
      document.location.href = "/login";
    }
  };
  render() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-9xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-4  sm:grid sm:grid-cols-3 sm:gap-4">
            <div className="content-between grid grid-cols-1 sm:col-span-3 lg:col-span-1">
                <div className="my-2">
                  <h3 className='text-left font-bold mt-6 text-lg'>
                    {LANGUAGE.Description.Content}
                  </h3>
                  <p className='text-left mt-2'>
                    {this.state.description}
                  </p>
                </div>
                <div className="my-2">
                  <div className='text-left'>
                    <button
                      onClick={this.handleButtonClick}
                      className="mt-2 block w-full bg-regal-blue rounded py-2 text-center text-base font-semibold text-white hover:bg-black"
                    >
                      {LANGUAGE.Download.Content}
                    </button>
                </div>
              </div>
            </div>
            <div className="sm:col-span-3 lg:col-span-2">
              <div className='text-left mt-2  h-96 overflow-x-auto'>
                <img src={this.state.reports[0]} alt="Report 1" />
                <img src={this.state.reports[1]} alt="Report 2" />
                <img src={this.state.reports[2]} alt="Report 3" />
                <img src={this.state.reports[3]} alt="Report 4" />
                <img src={this.state.reports[4]} alt="Report 5" />
                <img src={this.state.reports[5]} alt="Report 6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Example;