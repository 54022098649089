// import React from 'react';
import 'tw-elements';
import { Disclosure } from '@headlessui/react'

import Dashboard_main_personal from './componet/dashboard/Dashboard_main_personal';
import Dashboard_main_apikey from './componet/dashboard/Dashboard_main_apikey';
import Dashboard_main_subscription from './componet/dashboard/Dashboard_main_subscription';
import Dashboard_main_additional from './componet/dashboard/Dashboard_main_additional';
import Dashboard_main_referrals from './componet/dashboard/Dashboard_main_referrals';
import Dashboard_main_mfa from './componet/dashboard/Dashboard_main_mfa'; 
import Dashboard_mfa_enable from './componet/dashboard/mfa_enable'; 
import Home from './componet/home/Home';
import Login from './componet/login/login';
import Signout from './componet/login/signout';
import Registration from './componet/registration/registration';
import SMS_verify from './componet/registration/sms_verify';
import MFA_verify from './componet/registration/mfa_verify';
import MFA_set from './componet/registration/mfa_set';
import Login_mfa from './componet/login/login_mfa';
import Register_success from './componet/registration/register_success';
import Register_purchase_success from './componet/registration/register_purchase_success';
import Register_purchase_fail from './componet/registration/register_purchase_fail';
import Register_purchase_cancel from './componet/registration/register_purchase_cancel';
import Register_payment from './componet/registration/register_payment';
import Payment from './componet/payment/payment';
import Register_purchase_agreen from './componet/registration/register_purchase_agreen';
import PrivacyPolicy from './componet/home/PrivacyPolicy';
import Copyright from './componet/home/Copyright';
import News1 from './componet/home/News1';
import News2 from './componet/home/News2';
import News3 from './componet/home/News3';
import FAQ from './componet/home/FAQ';
import PasswordForget from './componet/password/passwordForget';
import PasswordVerify from './componet/password/passwordVerify';
import PasswordReset from './componet/password/passwordReset';
import PasswordSuccess from './componet/password/passwordSuccess';

import Footer from './componet/home/Footer';
import MenuModel from './componet/home/Menu';
import Contact from './componet/home/Contact';

import Templates from './componet/templates/templates';
import Templates_Scalping from './componet/templates/Scalping';

import {
    Routes, Route, Link,
} from "react-router-dom";
import { Config } from './componet/enum/config';
const IndexPage = () => {
    return (
        <div className="h-max">
            <div>
                <Disclosure as="nav" className="bg-regal-gray flex flex-col h-screen">
                    <div style={{ width: "100%" }}>
                        <MenuModel></MenuModel>
                     </div>
                    <div style={{ width: "100%" }} className="bg-regal-gray">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/dashboard_personal" element={<Dashboard_main_personal />} />
                            <Route path="/dashboard_apikey" element={<Dashboard_main_apikey />} />
                            <Route path="/dashboard_subscription" element={<Dashboard_main_subscription />} />
                            <Route path="/dashboard_referrals" element={<Dashboard_main_referrals />} />
                            <Route path="/dashboard_mfa" element={<Dashboard_main_mfa />} />
                            <Route path="/dashboard_additional" element={<Dashboard_main_additional />} />
                            <Route path="/mfa_enable" element={<Dashboard_mfa_enable />} />
                            <Route path="/registration" element={<Registration />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/login_mfa" element={<Login_mfa />} />
                            <Route path="/sms_verify" element={<SMS_verify />} />
                            <Route path="/mfa_verify" element={<MFA_verify />} />
                            <Route path="/mfa_set" element={<MFA_set />} />
                            <Route path="/register_success" element={<Register_success />} />
                            <Route path="/purchase_agreen" element={<Register_purchase_agreen />} />
                            <Route path="/purchase_success" element={<Register_purchase_success />} />
                            <Route path="/purchase_fail" element={<Register_purchase_fail />} />
                            <Route path="/purchase_cancel" element={<Register_purchase_cancel />} />
                            <Route path="/register_payment" element={<Register_payment />} />
                            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                            <Route path="/terms" element={<Copyright />} />
                            <Route path="/passwordForget" element={<PasswordForget />} />
                            <Route path="/passwordVerify" element={<PasswordVerify />} />
                            <Route path="/passwordReset" element={<PasswordReset />} />
                            <Route path="/passwordSuccess" element={<PasswordSuccess />} />
                            <Route path="/news1" element={<News1 />} />
                            <Route path="/news2" element={<News2 />} />
                            <Route path="/news3" element={<News3 />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/signout" element={<Signout />} />
                            {Config().Setting.Toggle.Template.Value? <Route path="/templates" element={<Templates />} /> : ""}
                            {Config().Setting.Toggle.Template.Value? <Route path="/templates_scalping" element={<Templates_Scalping />} /> : ""}
                        </Routes>
                    </div>
                    <Contact></Contact>
                    <Footer></Footer>
                </Disclosure>
            </div>
        </div>
    )
}

export default IndexPage;