import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  };
  render() {
    return (
      <div className="overflow-hidden bg-white font-bold shadow sm:rounded-lg">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">MFA</dt>
              <dd className="">
                <a
                  href={Enum().url.MFA_enable_frontend}
                  className="flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4"
                >
                  {LANGUAGE.Main.MFA.TurnOn}
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }
}

export default Example;
