export function Init(type) {
    const CN = {
        Menu:{
            AboutUs:'關於我們',
            Docs:'文檔',
            Pricing:'價格',
            Faq:'常見問題',
            Contact:'聯絡我們',
            Templates:'樣板'
        },
        MenuLogin:{
            Login:'登錄',
            MyAccount:' 我的帳號',
            SingUp:'註冊',
            Join:'加入 AlgoDojo',
        }
    };
    const EN = {
        Menu:{
            AboutUs:'ABOUT US',
            Docs:'DOCS',
            Pricing:'PRICING',
            Faq:'FAQ',
            Contact:'CONTACT',
            Templates:'TEMPLATES',
        },
        MenuLogin:{
            Login:'LOGIN',
            MyAccount:' MY ACCOUNT',
            SingUp:'Sign up',
            Join:'Join AlgoDojo',
        }
    };
    // return EN;
    if(type == 'cn'){
        return CN;
    }
    else{
        return EN;
    }
};

