import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Init } from '../language/dashborad/Dashboard_lang';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
const LANGUAGE = Init(localStorage.lang);
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btn_text: "Subscribe",
      open: false,
      showcancel: false,
      cancel_uuid: null,
    };
    this.GetData = this.GetData.bind(this);
    this.OpenModal = this.OpenModal.bind(this);
    this.ClossModal = this.ClossModal.bind(this);
    this.handleSubmit_confirm = this.handleSubmit_confirm.bind(this);
  };
  componentDidMount() {
    this.GetData();
  };
  async OpenModal() {
    this.setState({ open: true });
  };
  async ClossModal() {
    this.setState({ open: false });
  };
  async handleSubmit_confirm() {
    await this.ClossModal();
    await axios.post(Enum().url.cancel_subscription, { uuid: this.state.cancel_uuid })
      .then(async (res) => {
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
      });
  }
  async GetData() {
    await axios.post(Enum().url.db_subscription, '')
      .then(async (res) => {
        let responcessData = JSON.parse(res.data);
        const cancel_data = responcessData.CancelData;
        responcessData = responcessData.Data;
        // console.log('responcessData', responcessData);
        if (cancel_data.status) {
          this.setState({ showcancel: true });
          this.setState({ cancel_uuid: cancel_data.uuid });
        };

        document.getElementById('startDate').innerHTML = responcessData.startDate;
        document.getElementById('endDate').innerHTML = responcessData.endDate;
        this.setState({ endDate: responcessData.endDate });
        let personIdx = 0;

        for (let index = 0; index < responcessData.DataSet.length; index++) {
          const element = responcessData.DataSet[index];
          let Name = element.name;
          let Setname = element.Setname;
          // await this.Change_btn(Name);

          var _TrElement = document.createElement("tr");
          var class_tr = document.createAttribute('class');
          class_tr.value = (personIdx % 2 === 0 ? undefined : 'bg-gray-50');
          _TrElement.setAttributeNode(class_tr);

          var _TdElement1 = document.createElement("td");
          var class1 = document.createAttribute('class');
          class1.value = "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6";
          _TdElement1.setAttributeNode(class1);

          var _TdElement2 = document.createElement("td");
          var class2 = document.createAttribute('class');
          class2.value = "whitespace-nowrap px-3 py-4 text-sm text-gray-500";
          _TdElement2.setAttributeNode(class2);

          var text = document.createTextNode(Name);
          _TdElement1.appendChild(text);
          _TrElement.appendChild(_TdElement1);

          text = document.createTextNode(Setname);
          _TdElement2.appendChild(text);
          _TrElement.appendChild(_TdElement2);

          document.getElementById('dataset').appendChild(_TrElement);
          personIdx = personIdx + 1;
        };
      })
      .catch(err => {
        console.error(err);
      });
  };
  render() {
    return (
      <div className="overflow-hidden bg-white font-bold shadow sm:rounded-lg">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Subscription.StartDate}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="startDate"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Subscription.EndDate}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="endDate"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Subscription.DataSet.Title}</dt>
              <dd className="">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            {LANGUAGE.Main.Subscription.DataSet.Plan}
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {LANGUAGE.Main.Subscription.DataSet.DataSet}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white" id="dataset">
                      </tbody>
                    </table>
                  </div>

                </div>
              </dd>
            </div>
            {this.state.showcancel && (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Additional settings</dt>

                <dd className="">
                  <a
                    onClick={this.OpenModal}
                    target="_blank"
                    className="flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4"
                  >
                    Cancel Subscription
                  </a>
                </dd>
              </div>
            )}
          </dl>
        </div>
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.state.open}
            onClose={this.ClossModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Cancel Subscription
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500" id="modalText">
                            Are you sure you want to cancel your subscription?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={this.ClossModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="mx-4 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={this.handleSubmit_confirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    )
  }
}

export default Example;
