/* This example requires Tailwind CSS v2.0+ */
import Copyright_detail from './Copyright_detail';
// import Information from './information';
export default function Example() {
  return (
    // <div className="bg-white px-4 py-5 sm:px-6">
    // <div>
    
    // <div className="mx-auto max-w-2xl pt-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
    <div className="mx-auto max-w-2xl px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-20 w-auto"
              src="images/logo.png"
              alt="Your Company"
            />
            <h2 className="my-6 text-center text-3xl font-bold tracking-tight text-white">Terms & Conditions</h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-white hover:text-regal-blue">
                start your 30-day free trial
              </a>
            </p> */}
          </div>
      <div className="bg-white rounded-lg">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Copyright_detail></Copyright_detail>
        </div>
      </div>
    </div>
    // </div>
  )
}
