import { Init } from '../language/home/FAQ_lang';
const LANGUAGE = Init(localStorage.lang);
const div_className = "border-solid border-2 border-regal-gray rounded-md p-5 mt-6 ";
const h3_className = "text-left text-lg text-gray-500";
const p_className = 'text-left mt-2';

// const div_className = "border-solid border-2 border-regal-gray p-5 mt-6 ";
// const h3_className = "text-left text-lg ";
// const p_className = 'text-left mt-2 text-gray-500';
export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_1}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_1}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_2}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_2}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_3}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_3}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_4}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_4}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_5}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_5}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_6}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_6}
          </p>
        </div>
        <div class={div_className}>

          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_7}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_7}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_8}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_8}
          </p>
        </div>
        <div class={div_className}>

          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_9}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_9}
          </p>

        </div>
        <div class={div_className}>

          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_10}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_10}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_12}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_12}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_13}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_13}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_14}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_14}
          </p>
        </div>

        <div class={div_className}>
          <h3 className={h3_className}>
            {LANGUAGE.Detail.ContentTitle_15}
          </h3>
          <p className={p_className}>
            {LANGUAGE.Detail.ContentAns_15}
          </p>
        </div>

      </div>
    </div>
  )
}
