import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';

import { Init } from '../language/templates/templates_lang';
const LANGUAGE = Init(localStorage.lang);

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tiers: []
    };
    this.Reload = this.Reload.bind(this);
    this.GetData = this.GetData.bind(this);
    this.Reload();
  };
  async GetData() {
    await axios.get(Enum().url.getTemplateList, '')
      .then((res) => {
        let _TemplateData = res.data.data;
        console.log('_TemplateData', _TemplateData);
        // tiers = _TemplateData;
        // this.state.tiers = _TemplateData;
        this.setState((state) => ({ tiers: _TemplateData }));
      })
      .catch(err => {
        console.error(err);
      });
  };
  Reload() {
    console.log('reload');
    this.GetData();
  };
  render() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">

          <div className="space-y-4 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
            {this.state.tiers.map((tier) => (
              <div key={tier.name} className="divide-y divide-gray-200 shadow-sm">
                <div className="px-6 pt-12 pb-8 bg-regal-gray h-96">
                  <ul role="list" className="mt-6 space-y-4">
                    {tier.templatesDetail.map((element) => (
                      <li key={element} className="space-x-3 pt-4 h-28" style={{ 'borderTop': '1px dotted #e5e5e5' }}>
                        <h3 key={element.title}>
                          <span className="text-lg text-gray-300 font-bold">{element.title}</span>
                        </h3>

                        {element.Content.map((content) => (
                          <p key={content} className="text-left">
                            <span className="text-sm text-gray-300">{content}</span>
                          </p>
                        ))}
                      </li>
                    ))}
                    <li className="space-x-3 pt-4" style={{ 'borderTop': '1px dotted #e5e5e5' }}></li>
                  </ul>
                  <div className="px-24">
                    <a
                      href={tier.href}
                      className="mt-8 block w-full bg-regal-blue rounded py-2 text-center text-sm font-semibold text-white hover:bg-black"
                    >
                      {LANGUAGE.Button.Content}
                    </a>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    )
  }
}

export default Example;

