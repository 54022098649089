import { CheckIcon } from '@heroicons/react/20/solid'
import { Init } from '../language/login/Login_lang';
const LANGUAGE = Init(localStorage.lang);
export default function Example() {
  setTimeout(function(){
    localStorage.login_status = 0;
    window.location.replace("/")
  },500);
  return (
    <div className="bg-white h-screen">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-36 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <div className="block py-5">
          <div className="relative flex items-center justify-center">
            <span
              className='bg-green-500
                      h-24 w-24 rounded-full flex items-center justify-center ring-8 ring-white'
            >
              <CheckIcon className="h-12 w-12 text-white" aria-hidden="true" />
            </span>
          </div>
          </div>
          <span className="block">{LANGUAGE.SignOut}</span>
        </h2>
        <div className="mt-8 flex justify-center">
        </div>
      </div>
    </div>
  )
}
