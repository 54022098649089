export function Init(type) {
    const CN = {
        Title: {
            AboutUs: '關於我們',
            Contact: '聯絡我們',
        },
        Main: '透過單一API交易全球股票、期權、期貨、外匯、債券、差價合約',
        Form: {
            Name:'姓名',
            Email:'信箱',
            Subject:'主題',
            Message:'訊息',
            Button:'送出',
        }
    };
    const EN = {
        Title: {
            AboutUs: 'ABOUT US',
            Contact: 'CONTACT',
        },
        Main: 'QUANTITATIVELY TRADE STOCKS, OPTIONS, FUTURES, FX, BONDS, CFDs, CRYPTOCURRENCY ACROSS 150 MARKETS AROUND THE WORLD WITH ONE, SINGLE API',
        Form: {
            Name:'Name',
            Email:'Email',
            Subject:'Subject',
            Message:'Message',
            Button:'SEND MESSAGE',
        }
    };
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

