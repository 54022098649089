export function Init(type) {
    const CN = {
        Title: '常見問題',
        Detail: {
            ContentTitle_1: '甚麼是 AlgoDojo?',
            ContentAns_1: 'AlgoDojo 是一個架構在Python環境的API平台，用於創建量化交易演算法。用戶可以在開發過程中使用我們的歷史數據集來研究、構建和回測他們的演算法。我們通過盈透證券(IB)提供即時和模擬交易。',
            ContentTitle_2: '我需要盈透證券賬戶嗎？',
            ContentAns_2: '不需要！您可以在沒有盈透證券(IB)賬戶的情況下使用AlgoDojo的數據進行研究和回測。IB賬戶僅用於即時交易。',
            ContentTitle_3: '我是否需要知道如何編寫程式才能使用 AlgoDojo？',
            ContentAns_3: '了解簡單的程式語言編程是使用AlgoDojo的一體化、完全整合的交易基礎設施和歷史數據集來進行您的演算法交易的先決條件。',
            ContentTitle_4: 'AlgoDojo 如何連接到盈透證券？',
            ContentAns_4: "AlgoDojo 通過 IB Gateway 連接到 IB，連接 IB API 以蒐集即時市場數據、下單、查看賬戶餘額等。IB Gateway 與 AlgoDojo 結合，不需要單獨安裝。",
            ContentTitle_5: '即時數據與歷史數據。',
            ContentAns_5: '在您的演算法運行之前，我們完全按照我們的歷史數據庫的格式來處理 IB 的即時數據饋送，這樣您就不需要重新清理實時數據，只需插入並執行您的回測演算法即可進行即時市場交易。',
            ContentTitle_6: 'AlgoDojo提供哪些歷史數據？',
            ContentAns_6: 'AlgoDojo提供過去10年整個美國股票市場的美國日內歷史價格數據。',
            ContentTitle_7: 'AlgoDojo 提供哪些不同寬度的K棒？',
            ContentAns_7: '我們提供可被5秒整除的時間長度的任何K棒。(如5秒、10秒、15秒、30秒…)',
            ContentTitle_8: 'AlgoDojo 是否會針對股權分割和股息調整其歷史股票數據？',
            ContentAns_8: '我們的歷史數據未經拆分調整或股息調整。您必須查看我們的主要文件以查看詳細信息。',
            ContentTitle_9: '為什麼 AlgoDojo 上的歷史股票價格有時與其他數據提供商不同？',
            ContentAns_9: 'AlgoDojo 通過從分時數據構建我們的交易條來提供更準確的實時流媒體市場數據表示，並且不包括零股（100 股以下的交易）和暗池交易(dark pool trades)。我們過濾掉我們的數據供應商認為可疑的報價，這就是為什麼我們的交易欄可能與Google Finance等常見的網站略有不同。',
            ContentTitle_10: '我可以通過 AlgoDojo 交易哪些資產類別和市場？',
            ContentAns_10: '股票、期權、期貨、外匯、債券、ETF 和差價合約 (CFD)。使用 AlgoDojo，您可以透過一個單一的 API 在 33 個國家的 150 個交易所進行所有交易。',
            ContentTitle_11: '是否提供加密貨幣交易？',
            ContentAns_11: '您可以在 CBOE 或 CME 上交易比特幣期貨。',
            ContentTitle_12: 'AlgoDojo 是在我的電腦上運行還是在你的電腦上運行？',
            ContentAns_12: 'AlgoDojo 在您自己的硬體上運行，這可能是您的本機電腦或雲端服務器。',
            ContentTitle_13: '最低硬體要求是什麼？',
            ContentAns_13: 'AlgoDojo 在任何操作系統中的 Docker 上運行：Windows、Linux 或 Mac。對於 Windows 用戶，需要 Windows 10 專業版或更高版本，您的計算機應至少有 8 GB 的 RAM 以確保良好的用戶體驗。確切的要求取決於您的交易演算法的複雜性。',
            ContentTitle_14: '我可以一次進行超過 1 個演算法的即時交易嗎？',
            ContentAns_14: '是的！但這取決於您如何部署演算法的硬體系統。',
            ContentTitle_15: '你們的退款政策是什麼？',
            ContentAns_15: '考慮到我們產品的數字性質，訂閱和團隊成員許可證的付款不予退款。您可以隨時取消每月訂閱。當您取消時，將收取本月費用。如果您認為有計費錯誤，請發送電子郵件至support@alogdojo.com以獲得幫助。'
        }
    };
    const EN = {
        Title: 'Frequently Asked Questions',
        Detail: {
            ContentTitle_1: 'What is AlgoDojo?',
            ContentAns_1: 'AlgoDojo is a Python-based API platform to create quantitative trading algorithms. Users can use our historical data sets to research, build, and back test their algorithms during the development process. We provide live and paper trading through Interactive Brokers (IB).',
            ContentTitle_2: 'Do I need an Interactive Brokers account?',
            ContentAns_2: 'NO! You can do research and backtesting with AlgoDojo’s data sets without having an Interactive Brokers (IB) account. An IB account is only required for live trading.',
            ContentTitle_3: 'Do I need to know how to code to use AlgoDojo?',
            ContentAns_3: 'Knowledge of simple computer language programming is a pre-requisite to use AlgoDojo’s all-in-one, fully integrated trading infrastructure and historical data sets to trade your algorithms.',
            ContentTitle_4: 'How does AlgoDojo connect to Interactive Brokers?',
            ContentAns_4: "AlgoDojo connects to IB via the IB Gateway, accessing the IB API to collect live market data, place orders, check account balance, etc. IB Gateway is bundled with AlgoDojo and doesn't need to be installed separately.",
            ContentTitle_5: 'Live Data vs. Historical Data.',
            ContentAns_5: 'We massage IB’s live data feed exactly the way our historical database looks before it is processed by your algorithm. This way you don’t need to reclean the live data. You can just plug and execute your back testing algorithm to live market trading.',
            ContentTitle_6: 'What historical data sets does AlgoDojo provide?',
            ContentAns_6: 'AlgoDojo offers intraday U.S. historical price data of entire U.S. equity universe going back 10 years.',
            ContentTitle_7: 'What bar sizes are offered thru AlgoDojo?',
            ContentAns_7: 'We offer any bar sizes divisable by 5 seconds.',
            ContentTitle_8: 'Does AlgoDojo adjust its historical stock data for splits and dividends?',
            ContentAns_8: 'Our historical data is not split-adjusted or dividend-adjusted. You will have to check our Master File to see details.',
            ContentTitle_9: 'Why are historical equity prices on AlgoDojo sometimes different from other data providers?',
            ContentAns_9: 'AlgoDojo provides a more accurate representation of live streaming market data by building our trade bars from tick data and excludes odd lots (trades under 100 shares) and dark pool trades. We filter out ticks which our data vendor believes are suspicious, which is why our trade bars might vary slightly from popular web-portals like Google Finance, etc.',
            ContentTitle_10: 'What asset classes and markets can I trade via Interactive Brokers?',
            ContentAns_10: 'Stocks, options, futures, forex, bonds, ETFs, and contracts for differences (CFD). With AlgoDojo, you can trade all of them across 150 exchanges in 33 countries via one, single API.',
            ContentTitle_11: 'Are cryptocurrencies trading offered?',
            ContentAns_11: 'You can trade bitcoin futures on CBOE or CME.',
            ContentTitle_12: 'Does AlgoDojo run on my computer or yours?',
            ContentAns_12: 'AlgoDojo runs on your own hardware. This could be your local computer or a cloud server.',
            ContentTitle_13: 'What are minimum hardware requirements?',
            ContentAns_13: 'AlgoDojo runs on Docker in any OS: Windows, Linux, or Mac. For Windows users, Windows 10 Professional or higher is required. Your computer should have at least 8 GB of RAM to ensure a good user experience. Exact requirements depend on the complexity of your trading algorithms.',
            ContentTitle_14: 'Can I live trade more than 1 algorithm at a time?',
            ContentAns_14: 'Absolutely! But this depends on the hardware system that you are deploying your algorithms from.',
            ContentTitle_15: 'What is your refund policy?',
            ContentAns_15: 'Payments for subscriptions and team member licenses aren’t refundable, considering the digital nature of our product. You can cancel your monthly subscription at any time. When you cancel, you will be charged for the current month. If you think there’s been an error in billing, please email us at support@alogdojo.com for help.'
        }
    };
    // return EN;
    if(type == 'cn'){
        return CN;
    }
    else{
        return EN;
    }
};

