export function Init(type) {
    // const CN = {
    //     Main:{
    //         Content_1:'整合多個經紀商交易全球股票、期權、期貨、外匯、債券、差價合約的單一API ',
    //         Content_2:'',
    //         Content_3:'',
    //     },
    //     Button:{
    //         Content:'免費試用'
    //     }
    // };
    const EN = {
        Main:{
            News_1:{
                Title:'AlgoDojo Integrates with Interactive Brokers',
                DateTime:'Sep. 16, 2022',
                Content:'We are happy to announce we have integrated our backtesting and trading API with Interactive Brokers (IB).  We chose them to be the first partner to work with as their brokerage offers unparalleled access to global markets, offering traders access to 150 markets across 33 countries around the world.'
            },
            News_2:{
                Title:'Historical Data Offering Set to Grow',
                DateTime:'Sep. 16, 2022',
                Content:'Our initial offer of intraday U.S. historical price data of the entire U.S. equity universe going back 10 years has been greatly received by our clients, both institutional and professional day traders. Discovering and cleaning historical data has always been one of our company strong points as our founders have backgrounds in quantitative trading at various buy-side funds. Due to the positive community feedback from customers, we will continue to grow our historical data offering to provide our customers with more opportunity to build alpha generating algorithms with unique data sets.'
            },
            News_3:{
                Title:'Positive Feedback on Free Algorithm Templates',
                DateTime:'Oct. 21, 2022',
                Content:'We offer free trading algorithm templates to customers to help them save time in developing trading strategies so they can spend more time earning alpha. AlgoDojo customers are free to edit and change each trading algorithm according to their own liking and trading objectives.  Our templates serve as a base for them to further develop their trading strategy any way they see fit. We have received tremendous positive feedback from our users in regards to our free templates. Going forward, our team of quant researchers will continue to provide customers with more templates to help everyone in pursuit of alpha.'
            },
        },
        
    };
    return EN;
    // if(type == 'cn'){
    //     return CN;
    // }
    // else{
    //     return EN;
    // }
};

