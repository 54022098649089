import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.Reload = this.Reload.bind(this);
    this.GetData = this.GetData.bind(this);
    this.OpenModalRef = this.OpenModalRef.bind(this);
    this.ClossModalRef = this.ClossModalRef.bind(this);
    this.SendData = this.SendData.bind(this);
    this.Reload();
    this.cancelButtonRef = React.createRef(null);
  };
  async OpenModalRef(msg) {
    this.setState((state) => ({ open: true }));
  };
  async ClossModalRef() {
    this.setState((state) => ({ open: false }));
  };
  async GetData() {
    await axios.post(Enum().url.db_api, '')
      .then((res) => {
        let _CountryData = JSON.parse(res.data).Data;
        let Token = _CountryData.Token;
        document.getElementById('Token').innerHTML = Token;
      })
      .catch(err => {
        console.error(err);
      });
  };
  async SendData() {
    await this.ClossModalRef();
    await axios.post(Enum().url.db_api_generate, '')
      .then(async (res) => {
        await this.GetData();
        let _ResponseData = JSON.parse(res.data);
        console.log('_ResponseData', _ResponseData);
        if (_ResponseData.Result) {
        }
        else {
          await this.OpenModalRef(_ResponseData.ErrorMsg);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  Reload() {
    console.log('reload');
    this.GetData();
  };
  render() {

    return (
      <div className="overflow-hidden bg-white font-bold shadow sm:rounded-lg">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.ApiKey.CurrentKEY}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="Token"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500"></dt>
              <dd className="">
                <button
                  type="button"
                  className="flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4"
                  onClick={this.OpenModalRef}
                >
                  {LANGUAGE.Main.ApiKey.GetNewKEY}
                </button>
              </dd>
            </div>
          </dl>
        </div>
        {/* Modal */}
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={this.myRef} onClose={this.OpenModalRef}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          {LANGUAGE.Main.ApiKey.GetNewKEYObj.Attention}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {LANGUAGE.Main.ApiKey.GetNewKEYObj.Confirm}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        // onClick={() => this.setOpenModel(false)}
                        onClick={this.SendData}
                      >
                        {LANGUAGE.Main.ApiKey.GetNewKEYObj.Generate}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={this.ClossModalRef}
                      // ref={this.cancelButtonRef}
                      >
                        {LANGUAGE.Main.ApiKey.GetNewKEYObj.Cancel}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    )
  }
}

export default Example;


