/* This example requires Tailwind CSS v2.0+ */
import { Init } from '../language/home/Sider_lang';
const LANGUAGE = Init(localStorage.lang);
export default function Example() {
    return (
        // <div id="carouselExampleCaptions" className="carousel slide relative" data-bs-ride="carousel">
        <div id="carouselExampleCaptions" className="carousel slide relative h-screen" data-bs-ride="carousel">
            <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">

            </div>
            <div className="carousel-inner relative w-full overflow-hidden h-full">
                {/* <div className="carousel-inner relative w-full overflow-hidden "> */}
                <div className="carousel-item active relative float-left w-full text-center h-full">
                    {/* <div className="carousel-item active relative float-left w-full text-center"> */}
                    {/* <div className="absolute inset-0"> */}
                    <div className="absolute inset-0 h-full ">
                        <img
                            src="images/slider-main/slider_1.jpg"
                            className="block w-full h-full brightness-50"
                            // className="block w-full brightness-50"
                            alt="..."
                        />
                    </div>
                    <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                        <p className="mx-auto mt-6 max-w-lg text-center sm:text-1xl lg:text-3xl text-white sm:max-w-6xl">
                            {LANGUAGE.Main.Content_1}
                        </p>
                        <p className="mx-auto mt-6 max-w-lg text-center sm:text-1xl lg:text-3xl text-white sm:max-w-6xl">
                            {LANGUAGE.Main.Content_2}
                        </p>
                        <p className="mx-auto mt-6 max-w-lg text-center sm:text-1xl lg:text-3xl text-white sm:max-w-6xl">
                            {LANGUAGE.Main.Content_3}
                        </p>
                        <h1 className="text-center font-bold tracking-tight text-3xl lg:text-6xl pt-4">
                            <span className="block text-white">{LANGUAGE.Main.Content_4}</span>
                        </h1>
                        <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center px-24">
                            <div className="space-y-4 sm:inline-grid sm:grid-cols-1 sm:gap-5 sm:space-y-0">
                                <a
                                    href="/registration"
                                    className="font-bold bg-regal-blue flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base text-white shadow-sm hover:bg-white hover:text-regal-blue sm:px-8 "
                                    >
                                    {LANGUAGE.Button.Content}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
