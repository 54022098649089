import { Init } from '../language/home/Project_lang';
const LANGUAGE = Init(localStorage.lang);
const tiers = [
  {
    href: '/registration',
    includedFeatures: [
      {
        title: LANGUAGE.Main.Tiers_1.Title,
        Content: [LANGUAGE.Main.Tiers_1.Content1, LANGUAGE.Main.Tiers_1.Content2]
      }
      // 'Free 30 Day Trial - Get Access to Historical Data and API for 30 Days.'
    ],
  },
  {
    href: '/registration',
    description: 'One Single Line Code for Backtesting 4 Live Trade.',
    includedFeatures: [
      {
        title: LANGUAGE.Main.Tiers_2.Title,
        Content: []
      }
      // 'One Single Line Code for Backtesting 4 Live Trade.'
    ],
  },
  {
    href: '/registration',
    includedFeatures: [
      {
        title:LANGUAGE.Main.Tiers_3.Title,
        Content: []
      }
      // 'Brokers We Currently Sugger:Interactive Brekers Logo Others Coming Soon.'
    ],
  },
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-6xl py-24 px-4 sm:px-6 lg:px-8">
        <div className="space-y-4 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
          {tiers.map((tier) => (
            <div key={tier.name} className="divide-y divide-gray-200 shadow-sm text-center">
              <div className="px-6 pt-12 pb-8  text-center bg-regal-gray h-96">
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="space-x-3 pt-4 h-24" style={{ 'borderTop': '1px dotted #e5e5e5' }}>
                        <h3 key={feature.title}>
                          <span className="text-lg text-gray-300 font-bold">{feature.title}</span>
                        </h3>
                        
                      {feature.Content.map((content) => (
                        <p key={content}>
                          <span className="text-sm text-gray-300">{content}</span>
                        </p>
                      ))}
                    </li>
                  ))}
                  <li className="space-x-3 pt-4" style={{ 'borderTop': '1px dotted #e5e5e5' }}></li>
                </ul>
                <div className="px-24">
                  <a
                    href={tier.href}
                    className="mt-8 block w-full bg-regal-blue rounded py-2 text-center text-sm font-semibold text-white hover:bg-black"
                  >
                    {LANGUAGE.Button.Content}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
