export function Init(type) {
    const CN = {
        Main:{
            Content_1:'透過單一API交易全球股票、期權、期貨、外匯、債券、差價合約',
            Content_2:'',
            Content_3:'',
            Content_4:'',
        },
        Button:{
            Content:'免費試用'
        }
    };
    const EN = {
        Main:{
            Content_1:'QUANTITATIVELY TRADE STOCKS, OPTIONS, FUTURES, ',
            Content_2:'FX, BONDS, CFDs, & CRYPTOCURRENCY ACROSS',
            Content_3:' 150 MARKETS AROUND THE WORLD WITH',
            Content_4:'ONE, SINGLE API',
        },
        Button:{
            Content:'FREE TRIAL'
        }
    };
    // return EN;
    if(type == 'cn'){
        return CN;
    }
    else{
        return EN;
    }
};

