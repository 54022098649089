import { CheckIcon } from '@heroicons/react/20/solid'
export default function Example() {
  return (
    <div className="bg-white h-screen">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-36 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <div className="block py-5">
          <div className="relative flex items-center justify-center">
            <span
              className='bg-green-500
                      h-24 w-24 rounded-full flex items-center justify-center ring-8 ring-white'
            >
              <CheckIcon className="h-12 w-12 text-white" aria-hidden="true" />
            </span>
          </div>
          </div>
          <span className="block">Password Update Success</span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <a
              href="/login"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-5 py-3 text-base font-medium text-white hover:bg-black"
            >
              Go back Login
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
