import React, { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtag/js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','G-T8YF7EDH6H'); // Replace 'G-T8YF7EDH6H' with your GTM ID.
  }, []);

  return null;
};

export default GoogleTagManager;
