/* This example requires Tailwind CSS v2.0+ */
import { Init } from '../language/home/About_lang';
const LANGUAGE = Init(localStorage.lang);
export default function Example() {
  return (
    <div className="relative bg-black" id="section-about">
      <div className="relative mx-auto max-w-6xl py-24 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="grid grid-rows-1 gap-4 sm:grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1 mr-4">
            <h1 className="text-4xl font-bold tracking-tight text-gray-300 sm:text-3xl lg:text-3xl">{LANGUAGE.Head}</h1>
            <p className="mt-6 max-w-3xl text-base text-gray-300">
              {LANGUAGE.Main.Content1}
            </p>
            <p className="mt-6 max-w-3xl text-base text-gray-300">
              {LANGUAGE.Main.Content2}
            </p>
            <p className="mt-6 max-w-3xl text-base text-gray-300">
              {LANGUAGE.Main.Content3}
            </p>
          </div>
          <div className="col-span-1">
            <img className="h-48 auto-w text-center object-cover md:h-full" src="images/slider-pages/slide-page1.jpg" alt="Modern building architecture" />
          </div>
        </div>
      </div>
    </div>
  )
}
