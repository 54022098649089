
import React from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Enum } from '../enum/enum';
import {MenuContent} from './Dashboard_menu_content';
import axios from 'axios';
import {
  Bars3BottomLeftIcon,
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'
import Dashboard_MFA_disable from './Dashboard_mfa_disable';
import Dashboard_MFA_enable from './Dashboard_mfa_enable';
import Dashboard_menu from './Dashboard_menu';
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);
var navigation = MenuContent();
const navigationTitle = {
  Personal: LANGUAGE.Menu.Personal,
  API: LANGUAGE.Menu.ApiKey,
  Subscription: LANGUAGE.Menu.Subscription,
  Referrals: LANGUAGE.Menu.Referrals,
  MFA: LANGUAGE.Menu.MFA,
  Additional: LANGUAGE.Menu.Additional
}

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: LANGUAGE.PersonalMenu.SignOut, href: Enum().url.singout },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Example extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      MFA: false
    };
    this.Reload = this.Reload.bind(this);
    this.GetData = this.GetData.bind(this);
    this.setSidebarOpen = this.setSidebarOpen.bind(this);

  };
  componentDidMount() {
    this.Reload();
  }
  setSidebarOpen(val) {
    this.setState({ sidebarOpen: val });
  };
  async GetData() {
    await axios.get(Enum().url.MFA_isEnable, '')
      .then((res) => {
        this.setState({ MFA: res.data.status });
        // alert("test", res.status);
      })
      .catch(err => {
        console.error(err);
      });
  };
  async Reload() {
    console.log('reload');
    await this.GetData();
  };
  render() {
    return (
      <div>
        <Transition.Root show={this.setState.sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={this.setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-regal-gray pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => this.setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            'group flex items-center px-2 py-2 text-base font-bold rounded-md hover:bg-regal-blue text-white'
                          )}
                        >
                          <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Dashboard_menu></Dashboard_menu>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => this.setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1">
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className=" h-8 w-8 rounded-full text-black" aria-hidden="true" ></UserCircleIcon>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main>
            <div className="py-6 bg-white h-screen">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {navigationTitle.MFA}
                </h1>
              </div>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <div className="py-4">
                  {this.state.MFA ? (
                    <Dashboard_MFA_enable />
                  ) : (
                    <Dashboard_MFA_disable />
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default Example;
