// import './App.css';

// import {
//   Routes, Route,Link,
// } from "react-router-dom";
// import FirstPage from './pages/FirstPage';
// import SecondPage from './pages/SecondPage';
import IndexPage from './pages/Index';
const App = () => {
  return (
    <div>
      <IndexPage></IndexPage>
    </div>
  );
};

export default App;