import { Init } from '../language/home/Pricing_lang';
const LANGUAGE = Init(localStorage.lang);
const tiers = [
  {
    name: 'Freelancer',
    href: '/registration',
    priceMonthly: LANGUAGE.Main.Tiers_1.Price,
    Monthly: LANGUAGE.Main.Tiers_1.Monthly,
    level: LANGUAGE.Main.Tiers_1.Level,
    buttonText: LANGUAGE.Button,
    includedFeatures: [
      LANGUAGE.Main.Tiers_1.IncludedFeatures.Content_1,
      LANGUAGE.Main.Tiers_1.IncludedFeatures.Content_2,
      LANGUAGE.Main.Tiers_1.IncludedFeatures.Content_3,
      LANGUAGE.Main.Tiers_1.IncludedFeatures.Content_4
    ],
  },
  {
    name: 'Startup',
    href: '/registration',
    priceMonthly: LANGUAGE.Main.Tiers_2.Price,
    level: LANGUAGE.Main.Tiers_2.Level,
    Monthly: LANGUAGE.Main.Tiers_2.Monthly,
    buttonText: LANGUAGE.Button,
    includedFeatures: [
      LANGUAGE.Main.Tiers_2.IncludedFeatures.Content_1,
      LANGUAGE.Main.Tiers_2.IncludedFeatures.Content_2,
      LANGUAGE.Main.Tiers_2.IncludedFeatures.Content_3,
      LANGUAGE.Main.Tiers_2.IncludedFeatures.Content_4
    ],
  },
  {
    name: 'Enterprise',
    href: '/registration',
    priceMonthly: LANGUAGE.Main.Tiers_3.Price,
    level: LANGUAGE.Main.Tiers_3.Level,
    Monthly: LANGUAGE.Main.Tiers_3.Monthly,
    buttonText: LANGUAGE.Button,
    includedFeatures: [
      LANGUAGE.Main.Tiers_3.IncludedFeatures.Content_1,
      LANGUAGE.Main.Tiers_3.IncludedFeatures.Content_2,
      LANGUAGE.Main.Tiers_3.IncludedFeatures.Content_3,
      LANGUAGE.Main.Tiers_3.IncludedFeatures.Content_4
    ],
  },
]

export default function Example() {
  return (
    <div className="bg-black" id="section-price">
      <div className="mx-auto max-w-6xl py-24 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-black tracking-tight text-gray-400 text-center">{LANGUAGE.Title}</h1>
        <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
          {tiers.map((tier) => (
            <div key={tier.name} className="divide-y divide-gray-200 shadow-sm text-center">
              <span className="text-3xl font-medium text-white mb-16">{tier.level}</span>
              <div className="p-6 bg-regal-blue mt-6">
                <p className="mt-8">
                  <span className="text-4xl font-bold tracking-tight text-white">{tier.priceMonthly}</span>{' '}
                </p>
                <span className="text-base font-medium text-white">{tier.Monthly}</span>

              </div>
              <div className="px-6 pt-6 pb-8  text-center bg-regal-gray">
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="space-x-3 pt-4" style={{ 'borderTop': '1px dotted #e5e5e5' }}>
                      <span className="text-sm text-gray-300">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="px-24">
                  <a
                    href={tier.href}
                    className="mt-8 block w-full bg-regal-blue rounded py-2 text-center text-sm font-semibold text-white hover:bg-black"
                  >
                    {tier.buttonText}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
