/* This example requires Tailwind CSS v2.0+ */
import FAQ_detail from './FAQ_detail';
import { Init } from '../language/home/FAQ_lang';
const LANGUAGE = Init(localStorage.lang);
export default function Example() {
  return (
    <div className="mx-auto max-w-2xl px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="my-6 text-center text-3xl font-bold tracking-tight text-white">{LANGUAGE.Title}</h2>
          </div>
      <div className="bg-white rounded-lg">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <FAQ_detail></FAQ_detail>
        </div>
      </div>
    </div>
  )
}
