
import { Fragment, useState } from 'react'
import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  KeyIcon
} from '@heroicons/react/24/outline'
import { Init } from '../language/dashborad/Dashboard_lang';
import {MenuContent} from './Dashboard_menu_content';
const LANGUAGE = Init(localStorage.lang);
var navigation = MenuContent();
function setCurrent(data, name) {
  data.forEach(element => {
    if (element['name'] == name) {
      if (element['current']) {
        element['current'] = false;
      } else {
        element['current'] = true;
      }
    }
  });
}


export default function Example() {

  return (
    <>
      <div className="hidden md:fixed md:flex md:w-64 md:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto bg-regal-gray pt-5">
          <div className="flex flex-shrink-0 items-center">
          </div>
          <div className="flex flex-1 flex-col">
            <nav className="flex-1 space-y-1 px-2 pb-4">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  onClick={() => setCurrent(navigation, item.name)}
                  className='text-white hover:bg-regal-blue group flex items-center px-2 py-2 text-sm font-bold rounded-md'
                >
                  <item.icon
                    className="text-white  mr-3 h-6 w-6 flex-shrink-0"
                    aria-hidden="true" />
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
