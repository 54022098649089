import { Init } from '../language/home/Contact_lang';
import React from "react";
const LANGUAGE = Init(localStorage.lang);

export default function Example() {
  return (
    <div className="bg-black" id="section-contact">
      <section className="relative bg-black" aria-labelledby="contact-heading">
        {/* Decorative dot pattern */}
        <div className="mx-auto max-w-6xl bg-black">
          <div className="relative bg-white shadow-xl">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Contact information */}
              <div className="relative overflow-hidden bg-black from-teal-500 to-teal-600 py-10 px-6 sm:px-10 xl:p-12">
                <a href='#section-about'>
                  <h3 className="text-lg text-white font-bold" style={{ 'borderLeft': '3px solid #0abde3', 'paddingLeft': '15px' }}>{LANGUAGE.Title.AboutUs}</h3>
                </a>
                <div className="mx-auto text-left">
                  <img
                    // className="w-auto sm:h-2 mt-5"
                    className="text-left h-14 sm:h-14 pt-4"
                    src="images/logo_white.png"
                    alt=""
                  />
                </div>
                <p className="mt-6 max-w-3xl text-base text-gray-400">
                  {LANGUAGE.Main}
                </p>
                <ul role="list" className="mt-8 flex space-x-12">
                  <li>
                    <a className="text-regal-gray hover:text-teal-100" href="#">
                      <span className="sr-only">GitHub</span>
                      <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          fillRule="evenodd"
                          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a className="text-regal-gray hover:text-teal-100" href="#">
                      <span className="sr-only">Twitter</span>
                      <svg className="h-7 w-7" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12 bg-black">
                <h3 className="text-lg font-bold text-white border-regal-blue" style={{ 'borderLeft': '3px solid #0abde3', 'paddingLeft': '15px' }}>{LANGUAGE.Title.Contact}</h3>
                <form action="#" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-8">
                  <div>
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-400">
                      {LANGUAGE.Form.Name}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="bg-black block w-full border border-white text-gray-400 py-3 px-4 shadow-sm focus:border-regal-blue focus:regal-blue focus:bg-white"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-400">
                      {LANGUAGE.Form.Email}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="bg-black block w-full border border-white text-gray-400 py-3 px-4 shadow-sm focus:border-regal-blue focus:regal-blue focus:bg-white"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-400">
                    {LANGUAGE.Form.Subject}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="bg-black block w-full border border-white text-gray-400 py-3 px-4 shadow-sm focus:border-regal-blue focus:regal-blue focus:bg-white"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <div className="flex justify-between">
                      <label htmlFor="message" className="block text-sm font-medium text-gray-400">
                      {LANGUAGE.Form.Message}
                      </label>
                      <span id="message-max" className="text-sm text-warm-gray-500">
                        Max. 500 characters
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="bg-black block w-full border border-white text-gray-400 py-3 px-4 shadow-sm focus:border-regal-blue focus:regal-blue focus:bg-white"
                        aria-describedby="message-max"
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3 sm:flex sm:justify-end">
                    <button
                      type="submit"
                      className="mt-2 inline-flex w-full items-center justify-center rounded border border-transparent bg-regal-blue px-6 py-3 text-base font-algo font-bold text-white shadow-sm hover:text-regal-blue hover:bg-white focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto"
                    // className="mt-2 inline-flex w-full items-center justify-center rounded border border-transparent bg-regal-blue px-6 py-3 text-base font-bold text-white shadow-sm hover:text-regal-blue hover:bg-white focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:w-auto"
                    >
                     {LANGUAGE.Form.Button}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
