const CN = {
    Title: '樣板',
    Main: {
        Tiers_1: {
            Title: "三十天免費使用歷史數據資料庫與API",
            Content1: '',
            Content2: ''

        },
        Tiers_2: {
            Title: "使用同一個程式碼進行回溯測試與交易"
        },
        Tiers_3: {
            Title: '使用機構等級的日內歷史數據'
        }
    },
    Button: {
        Content: '免費試用'
    }
};
const EN = {
    Title: 'Templates',
    Main: {
        Tiers_1: {
            Title: "Scalping Algorithm",
            Content1: 'Scalping is one of the most popular strategies.',
            // Content2: '30 days.'
            
        },
        Tiers_2: {
            Title: 'Momentum Algorithm',
            Content1: 'This strategy usually involves trading on news releases.',
        },
        Tiers_3: {
            Title: "Daily Pivots Algorithm",
            Content1: "This strategy involves profiting from a stock's daily volatility.",
        }
    },
    Button: {
        Content: 'DETAILS'
    },
    Download:{
        Content: 'Download Code'
    },
    Description:{
        Content: 'Description'
    },
    Tamplates:{
        Scalping:{
            Title:"Scalping Algorithm"
        }
    }
};
export function Init(type) {
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

