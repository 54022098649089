import { Init } from '../language/home/News_lang';
const LANGUAGE = Init(localStorage.lang);
const blogPosts = [
  // {
  //   id: 3,
  //   title: 'Positive Feedback on Free Algorithm Templates',
  //   href: '/news3',
  //   date: '',
  //   datetime: '2020-02-12',
  //   category: { name: 'Case Study', href: '#' },
  //   imageUrl:
  //     'images/news/news3.jpg',
  //   preview:
  //     'Oct. 21, 2022',
  //   author: {
  //     name: 'Daniela Metz',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //     href: '#',
  //   },
  //   readingLength: '11 min',
  // },
  {
    id: 2,
    title: LANGUAGE.Main.News_2.Title,
    href: '/news2',
    date: '',
    datetime: '2020-03-10',
    category: { name: 'Video', href: '#' },
    imageUrl:
      'images/news/news2.jpg',
    preview:
      LANGUAGE.Main.News_2.DateTime,
    author: {
      name: 'Brenna Goyette',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      href: '#',
    },
    readingLength: '4 min',
  },
  {
    id: 1,
    title: LANGUAGE.Main.News_1.Title,
    href: '/news1',
    date: '',
    datetime: '2022-07-20',
    category: { name: 'Article', href: '#' },
    imageUrl:
      'images/news/news1.jpg',
    preview:
      LANGUAGE.Main.News_1.DateTime,
    author: {
      name: 'Roel Aufderehar',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      href: '',
    },
    readingLength: '6 min',
  }
]


export default function Example() {
  return (
    <div className="bg-white max-w">
      <div className="relative overflow-hidden max-w">
        <main>
          <div className="relative bg-regal-gray py-16 sm:py-24 lg:py-8 max-w">
            <div className="relative">
              <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-6xl lg:px-8">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-400 sm:text-4xl">NEWS</h1>
              </div>
              <div className="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-6xl lg:grid-cols-3 lg:px-8">
                {blogPosts.map((post) => (
                  <a href={post.href} target="_blank">
                    <div key={post.id} className="flex flex-col overflow-hidden">
                      <div className="flex-shrink-0">
                        <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="flex-1">
                          <a href={post.href} className="mt-2 block">
                            <p className="text-base font-semibold text-white">{post.title}</p>
                            <p className="mt-3 text-base text-white">{post.preview}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
