export function Init(type) {
    const CN = {
        Main:{
            Content1:'AlgoDojo是一項第三方服務的API，提供量化交易者提供完全無縫的整合工作流程的解決方案。我們將市場歷史數據、回溯測試和即時交易整合到一個簡單的 API。過去，交易者需要開發不同程式碼才能連接到各個證券公司的專有API，耗時的工作大幅減少了開發新交易策略的時間。AlgoDojo的單一 API讓用戶更能專注於新演算法開發，而不必花時間在系統開發和維護上。',
        },
        Head:'ABOUT US'
    };
    const EN = {
        Main:{
            Content1:"AlgoDojo's API is a cutting-edge, third-party quant trading service meticulously crafted to offer algo traders an impeccably seamless and integrated workflow solution. Within this innovative framework, we have seamlessly woven together historical market data, back testing, and live trading capabilities, all encapsulated within a singular, user-friendly API. Drawing upon our extensive experience as institutional quant traders on Wall Street, our API and system workflow epitomize the culmination of years of expertise.",
            Content2:"Gone are the days of laboriously developing different sets of trading algorithms to connect with the proprietary APIs of individual securities brokerages. This time-intensive process, which hampers the swift development of novel trading strategies, is now a thing of the past. AlgoDojo's singular API allow users to focus on developing new trading algorithms, sparing them from the cumbersome intricacies of system development and maintenance.",
            Content3:'Created by quant professionals for quants.',
        },
        Head:'ABOUT US'
    };
    // return EN;
    if(type == 'cn'){
        return CN;
    }
    else{
        return EN;
    }
};

