export function Init(type) {
    const CN = {
        Title: '支援經紀商'
    };
    const EN = {
        Title: 'SUPPORTED  BROKERAGE'
    };
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

