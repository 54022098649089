/* This example requires Tailwind CSS v2.0+ */
import { Init } from '../language/home/Service_lang';
const LANGUAGE = Init(localStorage.lang);
const people = [
  
    {
      name: 'Interactive',
      role: 'IBG LLC',
      imageUrl:
        'images/brokers/interactive.jpg',
    }
    
    // More people...
  ]
  
  export default function Example() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-8 sm:space-y-12">
            <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-gray-400">{LANGUAGE.Title}</h2>
            </div>
            <div
              role="list"
              className="mx-auto grid grid-cols-1 gap-x-4 grid-rows-3 gap-y-8 sm:grid-cols-1 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-1"
            >
              {people.map((person) => (
                  <div className="row-span-3">
                    <img className="mx-auto px-12 lg:px-80" src={person.imageUrl} alt="" />
                    {/* <div className="space-y-2">
                      <div className="text-xs font-medium lg:text-sm">
                        <h3>{person.name}</h3>
                        <p className="text-indigo-600">{person.role}</p>
                      </div>
                    </div> */}
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  