import { Init } from '../language/home/News_lang';
const LANGUAGE = Init(localStorage.lang);
export default function Example() {
  return (
    <div className="bg-white h-max">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
        <h3 className='text-left mt-2 text-lg font-bold'> 
          {LANGUAGE.Main.News_2.Title}
        </h3>
        <p className='text-left mt-2'>
          {LANGUAGE.Main.News_2.DateTime}
        </p>
        <p className='text-left mt-8'>
          {LANGUAGE.Main.News_2.Content}
        </p>
      </div>
    </div>
  )
}
