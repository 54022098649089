import React from 'react';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XCircleIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Enum } from '../enum/enum';
import { Init } from '../language/registration/information_lang';
const LANGUAGE = Init(localStorage.lang);
const products = [
  {
    id: 1,
    name: 'SILVER PLAN',
    href: '#',
    color: 'Billed Monthly',
    imageSrc: 'images/logo.png',
  },
  // More products...
];
let token = null;
let plan = null;
let price = null;
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      load: false,
      msg: '',
      total_price: 0,
    };
    this.handleSubmit_verify = this.handleSubmit_verify.bind(this);
    this.OpenModalError = this.OpenModalError.bind(this);
    this.ClossModalError = this.ClossModalError.bind(this);
    this.OpenModalLoad = this.OpenModalLoad.bind(this);
    this.ClossModalLoad = this.ClossModalLoad.bind(this);
    this.GetData = this.GetData.bind(this);
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');
    plan = urlParams.get('plan');
    price = parseInt(urlParams.get('price'));
    this.setState({ total_price: "$" + price.toString() + ".00" });
    this.GetData();
  }
  async GetData() {
    await axios.post(Enum().url.db_personal, '')
      // await axios.post('/payment/getToken/', '')
      .then((res) => {
        let PersonData = JSON.parse(res.data).Data;
        let Email = PersonData.email;
        document.getElementById('email-address').value = Email;
      })
      .catch(err => {
        console.error(err);
      });
  };
  async SendData(data) {
    await this.OpenModalLoad();
    await axios.post(Enum().url.transaction, data)
      .then(async (res) => {
        await this.ClossModalLoad();
        // console.log(res);
        // console.log(res.data.Result);
        if (res.data.Result) {
          // document.location.href = "/purchase_success";
          document.location.href = Enum().url.purchase_agreen;
        }
        else {
          await this.OpenModalError(res.data.ErrorMsg);
        }
      })
      .catch(async err => {
        await this.ClossModalLoad();
        console.error(err);
      });
  }
  async OpenModalError(msg) {
    this.setState({ msg: msg, open: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModalError() {
    this.setState({ open: false, msg: '' });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async OpenModalLoad() {
    this.setState({ load: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModalLoad() {
    this.setState({ load: false });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  async handleSubmit_verify() {
    let IsSend = true;
    const email = document.getElementById('email-address').value;
    const card_number = document.getElementById('card-number').value;
    const expiration_date = document.getElementById('expiration-date').value;
    const cvc = document.getElementById('cvc').value;
    // const city = document.getElementById('city').value;
    // const address = document.getElementById('address').value;
    // const region = document.getElementById('region').value;
    // const postal_code = document.getElementById('postal-code').value;
    var rules = new RegExp('[0-9]')
    const cvcRegex = /^\d{3}$/;
    const expiryDateRegex = /^(0[1-9]|1[0-2])\/(\d{2})$/;

    if (!expiryDateRegex.test(expiration_date)) {
      await this.OpenModalError(LANGUAGE.CheckText.expirationDate);
      IsSend = false;
    };
    if (!rules.test(card_number)) {
      await this.OpenModalError(LANGUAGE.CheckText.card_number);
      IsSend = false;
    };
    if (!rules.test(cvc) || !cvcRegex.test(cvc)) {
      await this.OpenModalError(LANGUAGE.CheckText.cvc);
      IsSend = false;
    };

    const FormData = {
      email: email,
      card_number: card_number,
      expiration_date: expiration_date,
      cvc: cvc,
      // city: city,
      // address: address,
      // region: region,
      // postal_code: postal_code,
      token: token,
      plan: plan,
    };
    // console.log('FormData', FormData);
    if (IsSend) {
      await this.SendData(FormData);
    };
  };
  render() {
    return (
      <div className="bg-white ">
        <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2   border">
          <h1 className="sr-only">Checkout</h1>
          <div className='bg-regal-gray'>
            <section
              aria-labelledby="summary-heading"
              className="bg-regal-gray py-12 text-indigo-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-16"
            >
              <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                <h2 id="summary-heading" className="sr-only">
                  Order summary
                </h2>

                <dl>
                  <dt className="text-sm font-medium text-regal-blue">Amount due</dt>
                  <dd className="mt-1 text-3xl font-bold tracking-tight text-white" >{this.state.total_price}</dd>
                </dl>

                <ul role="list" className="divide-y divide-white divide-opacity-10 text-sm font-medium">
                  {products.map((product) => (
                    <li key={product.id} className="flex items-start space-x-4 py-6">
                      <img
                        src={product.imageSrc}
                        alt={product.imageAlt}
                        className="h-20 w-20 flex-none rounded-md object-cover object-center"
                      />
                      <div className="flex-auto space-y-1">
                        <h3 className="text-white">{product.name}</h3>
                        <p className="text-white">{product.color}</p>
                      </div>
                      <p className="flex-none text-base font-medium text-white">{this.state.total_price}</p>
                    </li>
                  ))}
                </ul>

                <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium text-regal-blue">
                  <div className="flex items-center justify-between">
                    <dt>Subtotal</dt>
                    <dd id="sub_price">{this.state.total_price}</dd>
                  </div>

                  <div className="flex items-center justify-between">
                    <dt>Taxes</dt>
                    <dd>$00.00</dd>
                  </div>

                  <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base" >{this.state.total_price}</dd>
                  </div>
                </dl>
              </div>
            </section>
          </div>
          <section
            aria-labelledby="payment-and-shipping-heading"
            className="py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-16"
          >
            <h2 id="payment-and-shipping-heading" className="sr-only">
              Payment and shipping details
            </h2>

            <form>
              <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                <div>
                  <h3 id="contact-info-heading" className="text-lg font-medium text-regal-blue">
                    Contact information
                  </h3>

                  <div className="mt-6">
                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        id="email-address"
                        name="email-address"
                        autoComplete="email"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <h3 className="text-lg font-medium text-regal-blue">Payment details</h3>

                  <div className="mt-6 grid grid-cols-3 gap-x-4 gap-y-6 sm:grid-cols-4">
                    <div className="col-span-3 sm:col-span-4">
                      <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                        Card number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="card-number"
                          name="card-number"
                          autoComplete="cc-number"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="col-span-2 sm:col-span-3">
                      <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                        Expiration date (MM/YY)
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="expiration-date"
                          id="expiration-date"
                          autoComplete="cc-exp"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="cvc" className="block text-sm font-medium text-gray-700">
                        CVC
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="cvc"
                          id="cvc"
                          autoComplete="csc"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-10">
                  <h3 className="text-lg font-medium text-regal-blue">Shipping address</h3>

                  <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                    <div className="sm:col-span-3">
                      <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                        Address
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          autoComplete="street-address"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          autoComplete="address-level2"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                        State / Province
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="region"
                          name="region"
                          autoComplete="address-level1"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                        Postal code
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          id="postal-code"
                          name="postal-code"
                          autoComplete="postal-code"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-regal-blue focus:ring-regal-blue sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="mt-10">
                  <h3 className="text-lg font-medium text-regal-blue">Billing information</h3>

                  <div className="mt-6 flex items-center">
                    <input
                      id="same-as-shipping"
                      name="same-as-shipping"
                      type="checkbox"
                      defaultChecked
                      className="h-4 w-4 rounded border-regal-blue text-regal-blue focus:ring-regal-blue"
                    />
                    <div className="ml-2">
                      <label htmlFor="same-as-shipping" className="text-sm font-medium text-regal-blue">
                        Same as purchase  information
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-10 flex justify-end border-t border-gray-200 pt-6">
                  <button
                    type="button"
                    onClick={this.handleSubmit_verify}
                    className="rounded-md border border-transparent bg-regal-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-regal-gray focus:outline-none focus:ring-2 focus:ring-regal-blue focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    Pay now
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
        {/* Modal */}
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.state.open}
            onClose={this.ClossModalError}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Error
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500" id="modalText">
                            {/* Please check your input Information. */}
                            {this.state.msg}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        // onClick={this.ShowModalRef}
                        onClick={this.ClossModalError}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={this.state.load} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={this.ClossModalLoad}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"> */}
                        <div role="status">
                          <svg class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Loading...
                        </Dialog.Title>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    )
  }
}
export default Example;