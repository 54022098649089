import React from 'react';
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Init } from '../language/dashborad/Dashboard_lang';
const LANGUAGE = Init(localStorage.lang);

class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.Reload = this.Reload.bind(this);
    this.GetData = this.GetData.bind(this);
    this.Reload();
  };
  async GetData() {
    await axios.post(Enum().url.db_personal, '')
      .then((res) => {
        let _CountryData = JSON.parse(res.data).Data;
        console.log(_CountryData);
        // countryData.push(_CountryData[0]);
        let Email = _CountryData.email;
        let Username = _CountryData.username;
        let Phonenumber = _CountryData.phonenumber;
        document.getElementById('Email').innerHTML = Email;
        document.getElementById('Phonenumber').innerHTML = Phonenumber;
      })
      .catch(err => {
        console.error(err);
      });
  };
  Reload() {
    console.log('reload');
    this.GetData();
  };
  render() {
    return (
      <div className="overflow-hidden bg-white font-bold shadow sm:rounded-lg">
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Personal.Email}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="Email"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">{LANGUAGE.Main.Personal.Mobile}</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0" id="Phonenumber"></dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500"></dt>
              {/* <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0"> */}
              <dd className="">
                <a
                  href="/passwordForget"
                  target="_blank"
                  className="flex items-center justify-center rounded-md border border-transparent bg-regal-blue px-4 py-3 text-base text-white shadow-sm hover:bg-black sm:px-4"
                >
                  {LANGUAGE.Main.Personal.ChangePW}
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    )
  }
}

export default Example;
