export function Init(type) {
    const CN = {
        Main: {
            Tiers_1: {
                Level:'一般會員',
                Price: '免費',
                Monthly: '永久',
                IncludedFeatures: {
                    Content_1: '即時交易API',
                    Content_2: '回溯測試API',
                    Content_3: '無限制的演算法模板',
                    Content_4: '帶入自己的數據'
                }
            },
            Tiers_2: {
                Level:'銀色會員',
                Price: "$USD 19",
                Monthly: '每月',
                IncludedFeatures: {
                    Content_1: '即時交易API',
                    Content_2: '回溯測試API',
                    Content_3: '道瓊成分股',
                    Content_4: '無限制的演算法模板'
                }
            },
            Tiers_3: {
                Level:'黃金會員',
                Price: '$USD 49',
                Monthly: '每月',
                IncludedFeatures: {
                    Content_1: '即時交易API',
                    Content_2: '回溯測試API',
                    Content_3: '所有美股數據',
                    Content_4: '無限制的演算法模板'
                }
            }
        },
        Button: '免費試用'
    };
    const EN = {
        Title:'PRICING',
        Main: {
            Tiers_1: {
                Level:'FREE PLAN',
                Price: 'ALWAYS FREE',
                Monthly: 'FOREVER',
                IncludedFeatures: {
                    Content_1: 'Live trading API',
                    Content_2: 'Backtesting API',
                    Content_3: 'Unlimited algorithm templates',
                    Content_4: 'Bring your own data'
                }
            },
            Tiers_2: {
                Level:'SILVER PLAN',
                Price: "$USD 19",
                Monthly: '/MONTH',
                IncludedFeatures: {
                    Content_1: 'Live trading API',
                    Content_2: 'Backtesting API',
                    Content_3: 'Dow Jones Industrial Index constituents',
                    Content_4: 'Unlimited algorithm templates'
                }
            },
            Tiers_3: {
                Level:'GOLD PLAN',
                Price: '$USD 49',
                Monthly: '/MONTH',
                IncludedFeatures: {
                    Content_1: 'Live trading API',
                    Content_2: 'Backtesting API',
                    Content_3: 'Entire US equity universe',
                    Content_4: 'Unlimited algorithm templates'
                }
            }
        },
        Button:  'FREE TRIAL'
    };
    // return EN;
    if (type == 'cn') {
        return CN;
    }
    else {
        return EN;
    }
};

