import React from 'react';
import { Switch } from '@headlessui/react'
import axios from 'axios';
import { Enum } from '../enum/enum';
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Init } from '../language/registration/information_lang';
const LANGUAGE = Init(localStorage.lang);
let CountryIdDict = {};
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};
let referrals_code = "";
class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      agreed: false,
      open: false,
      password: false,
      msg: '',
    };
    this.Reload = this.Reload.bind(this);
    this.ToggleHandler = this.ToggleHandler.bind(this);
    this.OpenModalRef = this.OpenModalRef.bind(this);
    this.ClossModalRef = this.ClossModalRef.bind(this);
    this.ChangeCountry = this.ChangeCountry.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.PasswordSame = this.PasswordSame.bind(this);
    this.OpenModalLoad = this.OpenModalLoad.bind(this);
    this.ClossModalLoad = this.ClossModalLoad.bind(this);
    this.SendData = this.SendData.bind(this);
    this.GetData = this.GetData.bind(this);
    this.GetReferralsCode = this.GetReferralsCode.bind(this);
    this.CheckPwdRule = this.CheckPwdRule.bind(this);
    this.Reload();
    this.myRef = React.createRef(null);
    this.RefLoad = React.createRef(null);
  }
  CheckPwdRule(newpwd) {
    const len = 6;
    let pass = true;
    if (newpwd.length < len) {
      pass = false;
    }
    return pass;
  }
  async SendData(data) {
    await this.OpenModalLoad();
    await axios.post(Enum().url.send_sms, data)
      .then(async (res) => {
        await this.ClossModalLoad();
        let _ResponseData = JSON.parse(res.data);
        if (_ResponseData.Result) {
          document.location.href = "/sms_verify";
        }
        else {
          await this.OpenModalRef(_ResponseData.ErrorMsg);
        }
      })
      .catch(err => {
      });
  };
  async GetData() {
    await axios.get(Enum().url.get_country_list, '')
      .then((res) => {
        let _CountryData = JSON.parse(res.data);

        _CountryData.forEach(element => {
          CountryIdDict[element.name] = element.id;
          var _OptionElement = document.createElement("option");
          var text = document.createTextNode(element.name);
          var value = document.createAttribute('value');
          value.value = element.phoneNumber;
          _OptionElement.appendChild(text);
          _OptionElement.setAttributeNode(value);
          if (element.name == 'United States') {
            var selected = document.createAttribute('selected');
            selected.selected = 'selected';
            _OptionElement.setAttributeNode(selected);
            document.getElementById('country_id').value = element.id;
            document.getElementById('country-number').value = element.phoneNumber;
          }
          document.getElementById('country').appendChild(_OptionElement);
        });
      })
      .catch(err => {
      });
  };
  PasswordSame() {
    const Password = document.getElementById('password').value;
    const ConfirmPassword = document.getElementById('c-password').value;
    if (Password == ConfirmPassword) {
      this.setState((state) => ({ password: true }));
    }
    else {
      this.setState((state) => ({ password: false }));
    }
  };
  async OpenModalLoad() {
    this.setState({ load: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModalLoad() {
    this.setState({ load: false });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async OpenModalRef(msg) {
    this.setState({ msg: msg, open: true });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  async ClossModalRef() {
    this.setState({ open: false, msg: '' });
    await new Promise(resolve => setTimeout(resolve, 500));
  };
  ToggleHandler() {
    this.setState((state) => ({ agreed: !state.agreed }));
  };
  ChangeCountry() {
    let value = document.getElementById('country').value;
    let text = document.getElementById('country').options[document.getElementById('country').selectedIndex].text;
    document.getElementById('country-number').value = value;
    document.getElementById('country_id').value = CountryIdDict[text];
    document.getElementById('country-number').innerHTML = '+' + value;
  };
  Reload() {
    this.GetReferralsCode();
    this.GetData();
  };
  GetReferralsCode() {
    const queryParameters = new URLSearchParams(window.location.search)
    referrals_code = queryParameters.get("referrals");
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.ChangeCountry();
    let IsSend = true;
    const FirstName = document.getElementById('first-name').value;
    const LastName = document.getElementById('last-name').value;
    const Email = document.getElementById('email').value;
    const Password = document.getElementById('password').value;
    const ConfirmPassword = document.getElementById('c-password').value;
    const CountryId = document.getElementById('country_id').value;
    const CountryNumber = document.getElementById('country-number').value;
    const Phonenumber = document.getElementById('phone-number').value;
    var rules = new RegExp('[0-9]')
    if (Password != ConfirmPassword) {

      this.OpenModalRef(LANGUAGE.CheckText.ConfirmPassword);
      IsSend = false;
    }
    if (!this.CheckPwdRule(Password)) {
      this.OpenModalRef(LANGUAGE.CheckText.PasswordLen6);
      IsSend = false;
    }
    if (!rules.test(Phonenumber)) {
      this.OpenModalRef(LANGUAGE.CheckText.Phonenumber);
      IsSend = false;
    }
    if (!this.state.agreed) {
      this.OpenModalRef(LANGUAGE.CheckText.Agreed);
      IsSend = false;
    }
    const FormData = {
      FirstName: FirstName,
      LastName: LastName,
      Email: Email,
      PassWord: Password,
      ConfirmPassword: ConfirmPassword,
      CountryNumber: CountryNumber,
      CountryId: CountryId,
      Phonenumber: Phonenumber,
      ReferralsCode: referrals_code
    };
    for (var prop in FormData) {

      if (prop == "ReferralsCode") {
        continue;
      }

      FormData[prop] = FormData[prop].trim();
    };
    if (IsSend) {
      await this.SendData(FormData);
    };
  };
  render() {
    return (
      // <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
      <div className="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="relative mx-auto max-w-xl">
          <svg
            className="absolute left-full translate-x-1/2 transform"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
          </svg>
          <svg
            className="absolute right-full bottom-0 -translate-x-1/2 transform"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
          </svg>
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{LANGUAGE.Title}</h2>
          </div>
          <div className="mt-12">
            <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              onSubmit={this.handleSubmit}>
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.FirstName}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    required
                    // value="lin"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-regal-blue focus:ring-regal-blue"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.LastName}
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    required
                    // value="sam"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-regal-blue focus:ring-regal-blue"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.Email}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-regal-blue focus:ring-regal-blue"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.Password}
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    x-model="password"
                    required
                    onChange={this.PasswordSame}
                    autoComplete="organization"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-regal-blue focus:ring-regal-blue"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="c-password" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.ConfirmPassword}
                </label>
                <div className="mt-1">
                  <input
                    type="password"
                    name="c-password"
                    id="c-password"
                    required
                    x-model="password_confirm"
                    autoComplete="organization"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-regal-blue focus:ring-regal-blue"
                    onChange={this.PasswordSame}
                  />
                </div>
              </div>
              <Transition.Root show={!this.state.password} as={Fragment}>
                <div className="sm:col-span-2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="lg:my-2 my-3 ml-3">
                      <p className='text-base text-red-500'>{LANGUAGE.CheckText.PasswordNotMatch}</p>
                    </div>
                  </div>
                </div>
              </Transition.Root>
              <Transition.Root show={this.state.password} as={Fragment}>
                <div className="sm:col-span-2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="lg:my-2 my-3 ml-3">
                      <p className='text-base text-green-500'>{LANGUAGE.CheckText.PasswordMatch}</p>
                    </div>
                  </div>
                </div>
              </Transition.Root>

              <div className="sm:col-span-2">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.Country}
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    required
                    autoComplete="country-name"
                    className="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-regal-blue focus:ring-regal-blue"
                    onChange={this.ChangeCountry}
                  >
                  </select>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-sm font-medium text-gray-700">
                  {LANGUAGE.Form.PhoneNumber}
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <span
                      name="country-number"
                      className="h-full rounded-md border-transparent bg-transparent py-3 pl-4 pr-8 text-gray-500 focus:border-regal-blue focus:ring-regal-blue"
                      id="country-number"
                      value="+1"
                    >+1</span>
                  </div>
                  <input
                    type="text"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    required
                    className="block w-full rounded-md border-gray-300 py-3 px-4 pl-20 focus:border-regal-blue focus:ring-regal-blue"
                  />
                  <input type="hidden" id="country_id" value="0"></input>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <Switch
                      checked={this.state.agreed}
                      onChange={this.ToggleHandler}
                      className={classNames(
                        this.state.agreed ? 'bg-regal-blue' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-regal-blue focus:ring-offset-2'
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          this.state.agreed ? 'translate-x-5' : 'translate-x-0',
                          'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </div>
                  <div className="ml-3">
                    <p className="text-base text-gray-500">
                      {LANGUAGE.Form.Agreen.Content_1}{' '}
                      <a href="/privacypolicy" target="_blank" className="font-medium text-gray-700 underline">
                        {LANGUAGE.Form.Agreen.Policy}
                      </a>{' '}
                      {LANGUAGE.Form.Agreen.Content_2}{' '}
                      <a href="/terms" target="_blank" className="font-medium text-gray-700 underline">
                        {LANGUAGE.Form.Agreen.Conditions}{LANGUAGE.Form.Agreen.Content_3}
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-regal-blue px-6 py-3 text-base font-blod text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-regal-blue focus:ring-offset-2"
                >
                  {LANGUAGE.Button}
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* Modal */}
        <Transition.Root show={this.state.open} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={this.myRef} onClose={this.ClossModalRef}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Error
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500" id="modalText">
                            {this.state.msg}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={this.ClossModalRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={this.state.load} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={this.RefLoad} onClose={this.ClossModalLoad}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"> */}
                        <div role="status">
                          <svg class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          Loading...
                        </Dialog.Title>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    )
  }
}
export default Example;